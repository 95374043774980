.u-m {
    @each $size in map-keys($spacers) {
        &-#{$size} {
            margin-top: map-get($spacers, $size);
            margin-bottom: map-get($spacers, $size);
            margin-left: map-get($spacers, $size);
            margin-right: map-get($spacers, $size);
        }
    }
}

.u-mx {
    @each $size in map-keys($spacers) {
        &-#{$size} {
            margin-left: map-get($spacers, $size);
            margin-right: map-get($spacers, $size);
        }
    }
}

.u-ml {
    @each $size in map-keys($spacers) {
        &-#{$size} {
            margin-left: map-get($spacers, $size);
        }
    }
}

.u-mr {
    @each $size in map-keys($spacers) {
        &-#{$size} {
            margin-right: map-get($spacers, $size);
        }
    }
}

.u-my {
    @each $size in map-keys($spacers) {
        &-#{$size} {
            margin-top: map-get($spacers, $size);
            margin-bottom: map-get($spacers, $size);
        }
    }
}

.u-mt {
    @each $size in map-keys($spacers) {
        &-#{$size} {
            margin-top: map-get($spacers, $size);
        }
    }
}

.u-mb {
    @each $size in map-keys($spacers) {
        &-#{$size} {
            margin-bottom: map-get($spacers, $size);
        }
    }
}

@each $breakpoint in map-keys($breakpoints) {
    @media only screen and (min-width: breakpoint($breakpoint)) {
        .u-m {
            @each $size in map-keys($spacers) {
                &-#{$size}--#{$breakpoint} {
                    margin-top: map-get($spacers, $size);
                    margin-bottom: map-get($spacers, $size);
                    margin-left: map-get($spacers, $size);
                    margin-right: map-get($spacers, $size);
                }
            }
        }

        .u-mx {
            @each $size in map-keys($spacers) {
                &-#{$size}--#{$breakpoint} {
                    margin-left: map-get($spacers, $size);
                    margin-right: map-get($spacers, $size);
                }
            }
        }

        .u-ml {
            @each $size in map-keys($spacers) {
                &-#{$size}--#{$breakpoint} {
                    margin-left: map-get($spacers, $size);
                }
            }
        }

        .u-mr {
            @each $size in map-keys($spacers) {
                &-#{$size}--#{$breakpoint} {
                    margin-right: map-get($spacers, $size);
                }
            }
        }

        .u-my {
            @each $size in map-keys($spacers) {
                &-#{$size}--#{$breakpoint} {
                    margin-top: map-get($spacers, $size);
                    margin-bottom: map-get($spacers, $size);
                }
            }
        }

        .u-mt {
            @each $size in map-keys($spacers) {
                &-#{$size}--#{$breakpoint} {
                    margin-top: map-get($spacers, $size);
                }
            }
        }

        .u-mb {
            @each $size in map-keys($spacers) {
                &-#{$size}--#{$breakpoint} {
                    margin-bottom: map-get($spacers, $size);
                }
            }
        }
    }
}
