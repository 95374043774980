.o-footer {
    background-color: $colorBlack;
    color: $colorWhite;

    &__layout {
        display: grid;
        gap: 4rem;
    }
}

@media only screen and (min-width: breakpoint("md")) {
    .o-footer {
        &__layout {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}