.umbraco-forms {
    &-page {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
    }

    &-caption {
        @extend h3;
        margin-bottom: 0;
    }

    &-fieldset {
        padding: 0;
        margin: 0;
        border: 0;
    }

    &-container {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
    }

    &-label {
        font-size: 1.5rem;
        font-weight: 700;
    }

    &-field {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.4rem;
    }

    &-field-wrapper {
        width: 100%;
    }

    &-tooltip {
        font-size: 1.3rem;
        color: $colorGrey;
    }

    &-field-validation-valid,
    &-field-validation-error {
        font-size: 1.4rem;
    }

    &-navigation {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 0.8rem;

        .btn.next,
        .btn.prev {
            @extend .c-button;
            @extend .c-button--silver;
        }

        .btn.primary {
            @extend .c-button;
            @extend .c-button--yellow;
        }
    }
}
