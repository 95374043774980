.c-brand {
    display: inline-block;
    width: auto;
    height: 4.4rem;

    img {
        width: auto;
        height: 4.4rem;
    }
}

@media only screen and (min-width: breakpoint("md")) {
    .c-brand {
        height: 5.6rem;
    
        img {
            height: 5.6rem;
        }
    }
}