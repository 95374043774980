.u-p {
    @each $size in map-keys($spacers) {
        &-#{$size} {
            padding-top: map-get($spacers, $size);
            padding-bottom: map-get($spacers, $size);
            padding-left: map-get($spacers, $size);
            padding-right: map-get($spacers, $size);
        }
    }
}

.u-px {
    @each $size in map-keys($spacers) {
        &-#{$size} {
            padding-left: map-get($spacers, $size);
            padding-right: map-get($spacers, $size);
        }
    }
}

.u-pl {
    @each $size in map-keys($spacers) {
        &-#{$size} {
            padding-left: map-get($spacers, $size);
        }
    }
}

.u-pr {
    @each $size in map-keys($spacers) {
        &-#{$size} {
            padding-right: map-get($spacers, $size);
        }
    }
}

.u-py {
    @each $size in map-keys($spacers) {
        &-#{$size} {
            padding-top: map-get($spacers, $size);
            padding-bottom: map-get($spacers, $size);
        }
    }
}

.u-pt {
    @each $size in map-keys($spacers) {
        &-#{$size} {
            padding-top: map-get($spacers, $size);
        }
    }
}

.u-pb {
    @each $size in map-keys($spacers) {
        &-#{$size} {
            padding-bottom: map-get($spacers, $size);
        }
    }
}

@each $breakpoint in map-keys($breakpoints) {
    @media only screen and (min-width: breakpoint($breakpoint)) {
        .u-p {
            @each $size in map-keys($spacers) {
                &-#{$size}--#{$breakpoint} {
                    padding-top: map-get($spacers, $size);
                    padding-bottom: map-get($spacers, $size);
                    padding-left: map-get($spacers, $size);
                    padding-right: map-get($spacers, $size);
                }
            }
        }

        .u-px {
            @each $size in map-keys($spacers) {
                &-#{$size}--#{$breakpoint} {
                    padding-left: map-get($spacers, $size);
                    padding-right: map-get($spacers, $size);
                }
            }
        }

        .u-pl {
            @each $size in map-keys($spacers) {
                &-#{$size}--#{$breakpoint} {
                    padding-left: map-get($spacers, $size);
                }
            }
        }

        .u-pr {
            @each $size in map-keys($spacers) {
                &-#{$size}--#{$breakpoint} {
                    padding-right: map-get($spacers, $size);
                }
            }
        }

        .u-py {
            @each $size in map-keys($spacers) {
                &-#{$size}--#{$breakpoint} {
                    padding-top: map-get($spacers, $size);
                    padding-bottom: map-get($spacers, $size);
                }
            }
        }

        .u-pt {
            @each $size in map-keys($spacers) {
                &-#{$size}--#{$breakpoint} {
                    padding-top: map-get($spacers, $size);
                }
            }
        }

        .u-pb {
            @each $size in map-keys($spacers) {
                &-#{$size}--#{$breakpoint} {
                    padding-bottom: map-get($spacers, $size);
                }
            }
        }
    }
}
