legend {
    @extend h5;
    margin-bottom: 0.8rem;
}

input[type="text"],
input[type="email"],
input[type="number"] {
    display: block;
    width: 100%;
    padding: 0.8rem 1.6rem;
    height: 4.4rem;
    font-family: $fontFamilyDefault;
    font-size: 1.5rem;
    background-color: $colorWhite;
    border: 1px solid $colorSilver;

    &:focus {
        outline: 0;
        border-color: $colorGrey;
    }
}

input[type="checkbox"],
input[type="radio"],
input[type="range"],
progress {
    accent-color: $colorBlack;
}

textarea {
    display: block;
    width: 100%;
    padding: 1.6rem;
    font-family: $fontFamilyDefault;
    font-size: 1.5rem;
    background-color: $colorWhite;
    border: 1px solid $colorSilver;

    &:focus {
        outline: 0;
        border-color: $colorGrey;
    }
}

select {
    display: block;
    width: 100%;
    padding: 0.8rem 3.8rem 0.8rem 1.6rem;
    height: 4.4rem;
    font-family: $fontFamilyDefault;
    font-size: 1.5rem;
    background-color: $colorWhite;
    background-image: url("../img/chevron.svg");
    background-repeat: no-repeat;
    background-position: top 50% right 1.6rem;
    border: 1px solid $colorSilver;
    appearance: none;

    &::-ms-expand {
        display: none;
    }

    &:focus {
        outline: 0;
        border-color: $colorGrey;
    }
}

input[type="file"] {
    display: block;
    width: 100%;
    min-height: 4.4rem;
    padding: 0.8rem 3.8rem 0.8rem 1.6rem;
    font-family: $fontFamilyDefault;
    font-size: 1.5rem;
    background-color: $colorWhite;
    border: 1px solid $colorSilver;
    appearance: none;

    &:focus {
        outline: 0;
        border-color: $colorGrey;
    }
}

label,
select,
input[type="checkbox"],
input[type="radio"],
input[type="range"],
input[type="file"] {
    &:hover {
        cursor: pointer;
    }
}
