h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $fontFamilyTitle;
    font-weight: 700;
    line-height: 1.33em;
    color: inherit;

    &:last-child {
        margin-bottom: 0;
    }
}

h1 {
    font-size: 3.6rem;
    margin-bottom: calc(1.33em / 3.6 / 1.33);
}

h2 {
    font-size: 2.8rem;
    margin-bottom: calc(1.33em / 2.8 / 1.33);
}

h3 {
    font-size: 2.2rem;
    margin-bottom: calc(1.33em / 2.2 / 1.33);
}

h4 {
    font-size: 2rem;
    margin-bottom: calc(1.33em / 2 / 1.33);
}

h5 {
    font-size: 1.8rem;
    margin-bottom: calc(1.33em / 1.8 / 1.33);
}

h6 {
    font-size: 1.6rem;
    margin-bottom: calc(1.33em / 1.6 / 1.33);
}

p,
ol,
ul {
    margin-bottom: 1.66em;

    &:last-child {
        margin-bottom: 0;
    }
}

ol,
ul {
    padding-left: 1.6rem;
}

a:not([class]) {
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}