.c-dropdown {
    position: relative;

    &__summary {
        background-color: $colorWhite;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0.8rem 2.4rem;
        gap: 0.8rem;
        margin: 0;
        list-style: none;
        height: 4.4rem;
        font-family: $fontFamilyTitle;
        font-size: 1.5rem;
        font-weight: bold;
        text-decoration: none;
        text-transform: uppercase;
        user-select: none;

        &::marker,
        &::-webkit-details-marker {
            display: none;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__details {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 88;
        background-color: $colorWhite;
        @extend .u-shadow;
        max-height: 24rem;
        overflow: auto;
    }
}
