.c-accordeon {
    &__summary {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.8rem;
        list-style: none;
        min-height: 4.4rem;
        padding: 0.8rem 1.6rem;

        &::marker,
        &::-webkit-details-marker {
            display: none;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__details {
        font-size: 1.3rem;
    }

    &[open] {
        background-color: $colorUltraLightGrey;
    }
}
