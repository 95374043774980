.c-card {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background-color: $colorWhite;
  @extend .u-shadow;

  &__article-image {
    &--alt {
    }
  }

  &__article-body {
    &--alt {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(to top right, rgba($colorBlack, 1), rgba($colorBlack, 0));
      color: $colorWhite;
    }
  }

  &__article-pills {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    position: relative;
    top: -1.6rem;
    left: 0;
  }

  &__article-description {
    @supports (-webkit-line-clamp: 2) {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__event-description {
    @supports (min-height: 1lh) {
      min-height: 1lh;
    }

    @supports (-webkit-line-clamp: 1) {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

@media only screen and (min-width: breakpoint("xs")) {
  .c-card {
    &__event-body {
      flex-direction: row;
    }

    &__event-date {
      width: 9.6rem;
    }

    &__event-text {
      align-self: center;
    }
  }
}
