.o-header {
  position: sticky;
  z-index: 99;
  top: 0;
  left: 0;
  @extend .u-shadow;

  &-primary {
    background-color: $colorWhite;
    padding: 1.6rem 0;

    &__layout {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 2.4rem;
    }
  }

  &-secondary {
    display: none;
  }
}

@media only screen and (min-width: breakpoint("md")) {
  .o-header {
    &-primary {
      padding: 0;
    }

    &-secondary {
      display: block;
      background-color: $colorSilver;

      &__layout {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
