.c-carousel {
    &__list {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    &__item {
        p {
            font-size: 0.8em;
            text-align: center;
        }
    }

    &__image {
        width: 24rem;
        max-width: 100%;
        aspect-ratio: 4 / 3;
        object-fit: contain;
        object-position: center;
    }
}
