*,
*:before,
*:after {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
    background-color: $colorUltraLightGrey;
    font-family: $fontFamilyDefault;
    font-size: 1.6rem;
    font-weight: normal;
    line-height: 1.66em;
    color: $fontColorDefault;

    &.menu-is-open {
        position: fixed;
    }
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
    margin: 0;
    padding: 0;
}

img {
    display: block;
    max-width: 100%;
    width: auto;
    height: auto;
}

a {
    text-decoration: none;
    color: inherit;
}

@media only screen and (min-width: breakpoint("md")) {
    body {
        &.menu-is-open {
            position: static;
        }
    }
}
