table {
    width: 100%;
    border-collapse: collapse;
    border: 0;
    background-color: $colorWhite;
}

th,
td {
    padding: .8rem 1.6rem;
}

th {
    background-color: $colorBlack;
    text-align: left;
    color: $colorWhite;
}

tr:nth-child(even) > td {
    background-color: $colorSilver;
}