.c-grid {
  &__layout-container {
    position: relative;
    display: grid;
    grid-template-columns: repeat(var(--grid-columns, 1), minmax(0, 1fr));
    grid-auto-flow: row;
    grid-auto-rows: minmax(0, -webkit-min-content);
    grid-auto-rows: minmax(0, min-content);
    column-gap: 0;
    row-gap: var(--grid-row-gap, 0);
  }

  &__layout-item {
    position: relative;
    grid-column-end: span
      min(calc(var(--grid-item-column-span, 1) * 3), var(--grid-columns));
    grid-row: span var(--grid-item-row-span, 1);
  }
}

// .umb-block-grid__area-container,
// .umb-block-grid__block--view::part(area-container) {
//   position: relative;
//   display: grid;
//   grid-template-columns: repeat(
//     var(--umb-block-grid--area-grid-columns, var(--grid-columns, 1)),
//     minmax(0, 1fr)
//   );
//   grid-auto-flow: row;
//   grid-auto-rows: minmax(50px, -webkit-min-content);
//   grid-auto-rows: minmax(50px, min-content);

//   -moz-column-gap: var(--grid-column-gap, 0);

//   column-gap: var(--grid-column-gap, 0);
//   row-gap: var(--grid-row-gap, 0);
// }

// .umb-block-grid__area {
//   position: relative;
//   /* For small devices we scale columnSpan by three, to make everything bigger than 1/3 take full width: */
//   grid-column-end: span
//     min(
//       calc(var(--umb-block-grid--area-column-span, 1) * 3),
//       var(--grid-columns)
//     );
//   grid-row: span var(--umb-block-grid--area-row-span, 1);
// }

@media (min-width: map-get($breakpoints, "md")) {
  .c-grid {
    &__layout-container {
      grid-column-gap: var(--grid-column-gap, 0);
    }
    
    &__layout-item {
      grid-column-end: span
        min(var(--grid-item-column-span, 1), var(--grid-columns));
    }
  }

  // .umb-block-grid__area {
  //   grid-column-end: span var(--umb-block-grid--area-column-span, 1);
  // }
}
