.c-nav-header-primary {
  display: none;
}

.c-nav-header-secondary {
  .c-nav-header-secondary {
    display: block;

    &__list {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 2.4rem;
    }

    &__link {
      display: block;
      font-size: 1.4rem;
      text-decoration: none;
      color: $colorBlack;

      &:hover {
        text-decoration: underline;
      }

      .is-active & {
        text-decoration: underline;
      }
    }
  }
}

.c-nav-header-socials {
  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 1.6rem;
  }

  &__link {
    display: block;
    font-size: 1.4rem;
    text-decoration: none;
    color: $colorBlack;

    img {
      max-width: 24px;
    }
  }
}

.c-nav-footer {
  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
  }

  &__link {
    font-size: 1.6rem;
    text-decoration: none;
    color: $colorWhite;

    &:hover {
      color: $colorYellow;
    }

    .is-active & {
      color: $colorYellow;
    }
  }
}

.c-nav-megamenu {
  display: none;
  position: absolute;
  z-index: -1;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: $colorWhite;
  border-top: 1px solid $colorSilver;
  @extend .u-shadow;

  li:hover > & {
    display: block;
  }

  &__layout {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(28rem, 1fr));
    gap: 2.4rem;
  }

  &__title {
    font-family: $fontFamilyTitle;
    font-size: 1.4rem;
    text-transform: uppercase;
    margin-bottom: 0.8rem;
  }

  &__item {
    > ul {
      padding: 0;
      margin: 0;
      list-style: none;

      > li {
        & + li {
          margin-top: 0.8rem;
        }
      }
    }
  }
}

.c-nav-tags {
  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__item {
    &:nth-child(even) {
      background-color: $colorUltraLightGrey;
    }
  }

  &__link {
    display: block;
    padding: 0.4rem 1.2rem;
    font-size: 1.5rem;

    .is-active & {
      background-color: $colorYellow;
      font-weight: bold;
    }
  }
}

.c-nav-events {
  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__item {
    &:nth-child(even) {
      background-color: $colorUltraLightGrey;
    }
  }

  &__link {
    display: block;
    padding: 0.8rem 1.6rem;

    &:hover {
      text-decoration: underline;
    }
  }

  &__text-wrapper {
    @supports (-webkit-line-clamp: 1) {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

@media only screen and (min-width: breakpoint("md")) {
  .c-nav-header-primary {
    display: block;

    &__list {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 2.4rem;
    }

    &__item {
      display: flex;
      align-items: center;
      height: 9.6rem;
      border-top: 0.4rem solid transparent;
      border-bottom: 0.4rem solid transparent;

      &:hover,
      &.is-active {
        border-bottom-color: $colorYellow;
      }
    }

    &__link {
      align-items: center;
      font-size: 1.5rem;
      font-weight: bold;
      text-transform: uppercase;
      text-decoration: none;
      color: $colorBlack;

      &.has-megamenu {
        cursor: default;
        pointer-events: none;
      }
    }
  }
}

.c-nav-mobile {
  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__item {
    border-bottom: 1px solid $colorSilver;

    &:last-child {
      border-bottom: none;
    }
  }

  &__link,
  &__title {
    display: flex;
    align-items: center;
    min-height: 4.4rem;
    padding: 0.8rem 1.6rem;
  }

  &__title {
    font-size: 1.5rem;
  }
}
