.o-spotlight {
  &__layout {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);
    align-items: stretch;
    justify-items: start;
    gap: 2.4rem;
  }

  &__item {
    &:first-child {
      grid-column: span 3;
    }
  }

  &__certificate {
    grid-area: 1 / 3 / span 2 / span 1;
  }
}
