.o-media {
    &__layout {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1.6rem;
    }
}

@media only screen and (min-width: breakpoint("md")) {
    .o-media {
        &__layout {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}