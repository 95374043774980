.o-news {
    &__layout {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1.6rem;
    }

    &__pager {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 1.6rem;
    }
}

@media only screen and (min-width: breakpoint("md")) {
    .o-news {
        &__layout {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
