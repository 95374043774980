.c-pill {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 1.6rem;
    width: auto;
    height: 3.2rem;
    border-radius: 1.6rem;
    background-color: $colorSilver;
    color: $fontColorDefault;

    &--black {
        background-color: $colorBlack;
        color: $colorWhite
    }

    &--white {
        background-color: $colorWhite;
    }

    &--yellow {
        background-color: $colorYellow;
    }
}
