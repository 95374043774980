.o-events {
    &__layout {
        display: grid;
        grid-template-columns: 1fr;
        gap: 3.2rem;
    }

    &__filters {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 1.6rem;
    }
}

// @media only screen and (min-width: breakpoint("md")) {
//     .o-events {
//         &__layout {
//             grid-template-columns: repeat(2, 1fr);
//         }
//     }
// }