.c-menu {
    position: relative;
    z-index: 999;

    &__summary {
        display: flex;
        align-items: center;
        gap: 0.8rem;
        list-style: none;

        &::marker,
        &::-webkit-details-marker {
            display: none;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__label {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }

    &__icon {
        display: block;
        position: relative;
        width: 4.4rem;
        height: 4.4rem;

        [open] & {
            background-color: $colorWhite;
            @extend .u-shadow;
            position: fixed;
            top: 0;
            right: 0;
        }

        .line {
            width: 2rem;
            height: 0.2rem;
            background-color: $colorBlack;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -0.1rem;
            margin-left: -1rem;

            &.top {
                transform: translateY(-0.6rem);

                [open] & {
                    transform: rotate(45deg);
                }
            }

            &.mid {
                [open] & {
                    display: none;
                }
            }

            &.bot {
                transform: translateY(0.6rem);

                [open] & {
                    transform: rotate(-45deg);
                }
            }
        }
    }

    &__details {
        position: fixed;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $colorWhite;
        overflow: auto;
    }
}

@media only screen and (min-width: breakpoint("md")) {
    .c-menu {
        display: none;
    }
}
