*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  background-color: #f6f6f6;
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.66em;
  color: #222222;
}
body.menu-is-open {
  position: fixed;
}

body,
h1,
h2,
h3,
.umbraco-forms-caption,
h4,
h5,
legend,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
}

img {
  display: block;
  max-width: 100%;
  width: auto;
  height: auto;
}

a {
  text-decoration: none;
  color: inherit;
}

@media only screen and (min-width: 768px) {
  body.menu-is-open {
    position: static;
  }
}
h1,
h2,
h3,
.umbraco-forms-caption,
h4,
h5,
legend,
h6 {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  line-height: 1.33em;
  color: inherit;
}
h1:last-child,
h2:last-child,
h3:last-child,
.umbraco-forms-caption:last-child,
h4:last-child,
h5:last-child,
legend:last-child,
h6:last-child {
  margin-bottom: 0;
}

h1 {
  font-size: 3.6rem;
  margin-bottom: 0.2777777778em;
}

h2 {
  font-size: 2.8rem;
  margin-bottom: 0.3571428571em;
}

h3, .umbraco-forms-caption {
  font-size: 2.2rem;
  margin-bottom: 0.4545454545em;
}

h4 {
  font-size: 2rem;
  margin-bottom: 0.5em;
}

h5, legend {
  font-size: 1.8rem;
  margin-bottom: 0.5555555556em;
}

h6 {
  font-size: 1.6rem;
  margin-bottom: 0.625em;
}

p,
ol,
ul {
  margin-bottom: 1.66em;
}
p:last-child,
ol:last-child,
ul:last-child {
  margin-bottom: 0;
}

ol,
ul {
  padding-left: 1.6rem;
}

a:not([class]) {
  text-decoration: underline;
}
a:not([class]):hover {
  text-decoration: none;
}

legend {
  margin-bottom: 0.8rem;
}

input[type=text],
input[type=email],
input[type=number] {
  display: block;
  width: 100%;
  padding: 0.8rem 1.6rem;
  height: 4.4rem;
  font-family: "Roboto", sans-serif;
  font-size: 1.5rem;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
}
input[type=text]:focus,
input[type=email]:focus,
input[type=number]:focus {
  outline: 0;
  border-color: #aaaaaa;
}

input[type=checkbox],
input[type=radio],
input[type=range],
progress {
  accent-color: #222222;
}

textarea {
  display: block;
  width: 100%;
  padding: 1.6rem;
  font-family: "Roboto", sans-serif;
  font-size: 1.5rem;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
}
textarea:focus {
  outline: 0;
  border-color: #aaaaaa;
}

select {
  display: block;
  width: 100%;
  padding: 0.8rem 3.8rem 0.8rem 1.6rem;
  height: 4.4rem;
  font-family: "Roboto", sans-serif;
  font-size: 1.5rem;
  background-color: #ffffff;
  background-image: url("../img/chevron.svg");
  background-repeat: no-repeat;
  background-position: top 50% right 1.6rem;
  border: 1px solid #eeeeee;
  appearance: none;
}
select::-ms-expand {
  display: none;
}
select:focus {
  outline: 0;
  border-color: #aaaaaa;
}

input[type=file] {
  display: block;
  width: 100%;
  min-height: 4.4rem;
  padding: 0.8rem 3.8rem 0.8rem 1.6rem;
  font-family: "Roboto", sans-serif;
  font-size: 1.5rem;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  appearance: none;
}
input[type=file]:focus {
  outline: 0;
  border-color: #aaaaaa;
}

label:hover,
select:hover,
input[type=checkbox]:hover,
input[type=radio]:hover,
input[type=range]:hover,
input[type=file]:hover {
  cursor: pointer;
}

table {
  width: 100%;
  border-collapse: collapse;
  border: 0;
  background-color: #ffffff;
}

th,
td {
  padding: 0.8rem 1.6rem;
}

th {
  background-color: #222222;
  text-align: left;
  color: #ffffff;
}

tr:nth-child(even) > td {
  background-color: #eeeeee;
}

.o-container {
  max-width: 100%;
  margin: 0 auto;
}
.o-container--default {
  width: 1280px;
}

.o-header {
  position: sticky;
  z-index: 99;
  top: 0;
  left: 0;
}
.o-header-primary {
  background-color: #ffffff;
  padding: 1.6rem 0;
}
.o-header-primary__layout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2.4rem;
}
.o-header-secondary {
  display: none;
}

@media only screen and (min-width: 768px) {
  .o-header-primary {
    padding: 0;
  }
  .o-header-secondary {
    display: block;
    background-color: #eeeeee;
  }
  .o-header-secondary__layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.o-spotlight__layout {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto);
  align-items: stretch;
  justify-items: start;
  gap: 2.4rem;
}
.o-spotlight__item:first-child {
  grid-column: span 3;
}
.o-spotlight__certificate {
  grid-area: 1/3/span 2/span 1;
}

.o-events__layout {
  display: grid;
  grid-template-columns: 1fr;
  gap: 3.2rem;
}
.o-events__filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
}

.o-news__layout {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.6rem;
}
.o-news__pager {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
}

@media only screen and (min-width: 768px) {
  .o-news__layout {
    grid-template-columns: repeat(3, 1fr);
  }
}
.o-media__layout {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.6rem;
}

@media only screen and (min-width: 768px) {
  .o-media__layout {
    grid-template-columns: repeat(4, 1fr);
  }
}
.o-sponsors {
  background-color: #ffffff;
}

.o-footer {
  background-color: #222222;
  color: #ffffff;
}
.o-footer__layout {
  display: grid;
  gap: 4rem;
}

@media only screen and (min-width: 768px) {
  .o-footer__layout {
    grid-template-columns: repeat(4, 1fr);
  }
}
.c-grid__layout-container {
  position: relative;
  display: grid;
  grid-template-columns: repeat(var(--grid-columns, 1), minmax(0, 1fr));
  grid-auto-flow: row;
  grid-auto-rows: minmax(0, -webkit-min-content);
  grid-auto-rows: minmax(0, min-content);
  column-gap: 0;
  row-gap: var(--grid-row-gap, 0);
}
.c-grid__layout-item {
  position: relative;
  grid-column-end: span min(var(--grid-item-column-span, 1) * 3, var(--grid-columns));
  grid-row: span var(--grid-item-row-span, 1);
}

@media (min-width: 768px) {
  .c-grid__layout-container {
    grid-column-gap: var(--grid-column-gap, 0);
  }
  .c-grid__layout-item {
    grid-column-end: span min(var(--grid-item-column-span, 1), var(--grid-columns));
  }
}
.c-brand {
  display: inline-block;
  width: auto;
  height: 4.4rem;
}
.c-brand img {
  width: auto;
  height: 4.4rem;
}

@media only screen and (min-width: 768px) {
  .c-brand {
    height: 5.6rem;
  }
  .c-brand img {
    height: 5.6rem;
  }
}
.c-nav-header-primary {
  display: none;
}

.c-nav-header-secondary .c-nav-header-secondary {
  display: block;
}
.c-nav-header-secondary .c-nav-header-secondary__list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 2.4rem;
}
.c-nav-header-secondary .c-nav-header-secondary__link {
  display: block;
  font-size: 1.4rem;
  text-decoration: none;
  color: #222222;
}
.c-nav-header-secondary .c-nav-header-secondary__link:hover {
  text-decoration: underline;
}
.is-active .c-nav-header-secondary .c-nav-header-secondary__link {
  text-decoration: underline;
}

.c-nav-header-socials__list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  gap: 1.6rem;
}
.c-nav-header-socials__link {
  display: block;
  font-size: 1.4rem;
  text-decoration: none;
  color: #222222;
}
.c-nav-header-socials__link img {
  max-width: 24px;
}

.c-nav-footer__list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}
.c-nav-footer__link {
  font-size: 1.6rem;
  text-decoration: none;
  color: #ffffff;
}
.c-nav-footer__link:hover {
  color: #f0f030;
}
.is-active .c-nav-footer__link {
  color: #f0f030;
}

.c-nav-megamenu {
  display: none;
  position: absolute;
  z-index: -1;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  border-top: 1px solid #eeeeee;
}
li:hover > .c-nav-megamenu {
  display: block;
}
.c-nav-megamenu__layout {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(28rem, 1fr));
  gap: 2.4rem;
}
.c-nav-megamenu__title {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.4rem;
  text-transform: uppercase;
  margin-bottom: 0.8rem;
}
.c-nav-megamenu__item > ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.c-nav-megamenu__item > ul > li + li {
  margin-top: 0.8rem;
}

.c-nav-tags__list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.c-nav-tags__item:nth-child(even) {
  background-color: #f6f6f6;
}
.c-nav-tags__link {
  display: block;
  padding: 0.4rem 1.2rem;
  font-size: 1.5rem;
}
.is-active .c-nav-tags__link {
  background-color: #f0f030;
  font-weight: bold;
}

.c-nav-events__list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.c-nav-events__item:nth-child(even) {
  background-color: #f6f6f6;
}
.c-nav-events__link {
  display: block;
  padding: 0.8rem 1.6rem;
}
.c-nav-events__link:hover {
  text-decoration: underline;
}
@supports (-webkit-line-clamp: 1) {
  .c-nav-events__text-wrapper {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

@media only screen and (min-width: 768px) {
  .c-nav-header-primary {
    display: block;
  }
  .c-nav-header-primary__list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 2.4rem;
  }
  .c-nav-header-primary__item {
    display: flex;
    align-items: center;
    height: 9.6rem;
    border-top: 0.4rem solid transparent;
    border-bottom: 0.4rem solid transparent;
  }
  .c-nav-header-primary__item:hover, .c-nav-header-primary__item.is-active {
    border-bottom-color: #f0f030;
  }
  .c-nav-header-primary__link {
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    color: #222222;
  }
  .c-nav-header-primary__link.has-megamenu {
    cursor: default;
    pointer-events: none;
  }
}
.c-nav-mobile__list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.c-nav-mobile__item {
  border-bottom: 1px solid #eeeeee;
}
.c-nav-mobile__item:last-child {
  border-bottom: none;
}
.c-nav-mobile__link, .c-nav-mobile__title {
  display: flex;
  align-items: center;
  min-height: 4.4rem;
  padding: 0.8rem 1.6rem;
}
.c-nav-mobile__title {
  font-size: 1.5rem;
}

.c-button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
}

.c-button, .umbraco-forms-navigation .btn.primary, .umbraco-forms-navigation .btn.next,
.umbraco-forms-navigation .btn.prev {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem 2.4rem;
  min-height: 4.4rem;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid transparent;
}
.c-button:hover, .umbraco-forms-navigation .btn.primary:hover, .umbraco-forms-navigation .btn.next:hover,
.umbraco-forms-navigation .btn.prev:hover {
  cursor: pointer;
}
.c-button--yellow, .umbraco-forms-navigation .btn.primary {
  background-color: #f0f030;
}
.c-button--yellow:hover, .umbraco-forms-navigation .btn.primary:hover {
  background-color: #dddd10;
}
.c-button--silver, .umbraco-forms-navigation .btn.next,
.umbraco-forms-navigation .btn.prev {
  background-color: #eeeeee;
}
.c-button--silver:hover, .umbraco-forms-navigation .btn.next:hover,
.umbraco-forms-navigation .btn.prev:hover {
  background-color: #d5d5d5;
}
.c-button--black {
  background-color: #222222;
  color: #ffffff;
}
.c-button--black:hover {
  background-color: #090909;
}
.c-button--white {
  background-color: #ffffff;
}
.c-button--white:hover {
  background-color: #e6e6e6;
}
.c-button--link {
  text-decoration: underline;
}
.c-button--link:hover {
  background-color: rgba(34, 34, 34, 0.1);
}

.c-card {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}
.c-card__article-body--alt {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top right, #222222, rgba(34, 34, 34, 0));
  color: #ffffff;
}
.c-card__article-pills {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  position: relative;
  top: -1.6rem;
  left: 0;
}
@supports (-webkit-line-clamp: 2) {
  .c-card__article-description {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
@supports (min-height: 1lh) {
  .c-card__event-description {
    min-height: 1lh;
  }
}
@supports (-webkit-line-clamp: 1) {
  .c-card__event-description {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

@media only screen and (min-width: 480px) {
  .c-card__event-body {
    flex-direction: row;
  }
  .c-card__event-date {
    width: 9.6rem;
  }
  .c-card__event-text {
    align-self: center;
  }
}
.c-pill {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.6rem;
  width: auto;
  height: 3.2rem;
  border-radius: 1.6rem;
  background-color: #eeeeee;
  color: #222222;
}
.c-pill--black {
  background-color: #222222;
  color: #ffffff;
}
.c-pill--white {
  background-color: #ffffff;
}
.c-pill--yellow {
  background-color: #f0f030;
}

.c-dropdown {
  position: relative;
}
.c-dropdown__summary {
  background-color: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem 2.4rem;
  gap: 0.8rem;
  margin: 0;
  list-style: none;
  height: 4.4rem;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  user-select: none;
}
.c-dropdown__summary::marker, .c-dropdown__summary::-webkit-details-marker {
  display: none;
}
.c-dropdown__summary:hover {
  cursor: pointer;
}
.c-dropdown__details {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 88;
  background-color: #ffffff;
  max-height: 24rem;
  overflow: auto;
}

.c-carousel__list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.c-carousel__item p {
  font-size: 0.8em;
  text-align: center;
}
.c-carousel__image {
  width: 24rem;
  max-width: 100%;
  aspect-ratio: 4/3;
  object-fit: contain;
  object-position: center;
}

.umbraco-forms-page {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}
.umbraco-forms-caption {
  margin-bottom: 0;
}
.umbraco-forms-fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}
.umbraco-forms-container {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}
.umbraco-forms-label {
  font-size: 1.5rem;
  font-weight: 700;
}
.umbraco-forms-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.4rem;
}
.umbraco-forms-field-wrapper {
  width: 100%;
}
.umbraco-forms-tooltip {
  font-size: 1.3rem;
  color: #aaaaaa;
}
.umbraco-forms-field-validation-valid, .umbraco-forms-field-validation-error {
  font-size: 1.4rem;
}
.umbraco-forms-navigation {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0.8rem;
}
.c-accordeon__summary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.8rem;
  list-style: none;
  min-height: 4.4rem;
  padding: 0.8rem 1.6rem;
}
.c-accordeon__summary::marker, .c-accordeon__summary::-webkit-details-marker {
  display: none;
}
.c-accordeon__summary:hover {
  cursor: pointer;
}
.c-accordeon__details {
  font-size: 1.3rem;
}
.c-accordeon[open] {
  background-color: #f6f6f6;
}

.c-menu {
  position: relative;
  z-index: 999;
}
.c-menu__summary {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  list-style: none;
}
.c-menu__summary::marker, .c-menu__summary::-webkit-details-marker {
  display: none;
}
.c-menu__summary:hover {
  cursor: pointer;
}
.c-menu__label {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.c-menu__icon {
  display: block;
  position: relative;
  width: 4.4rem;
  height: 4.4rem;
}
[open] .c-menu__icon {
  background-color: #ffffff;
  position: fixed;
  top: 0;
  right: 0;
}
.c-menu__icon .line {
  width: 2rem;
  height: 0.2rem;
  background-color: #222222;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -0.1rem;
  margin-left: -1rem;
}
.c-menu__icon .line.top {
  transform: translateY(-0.6rem);
}
[open] .c-menu__icon .line.top {
  transform: rotate(45deg);
}
[open] .c-menu__icon .line.mid {
  display: none;
}
.c-menu__icon .line.bot {
  transform: translateY(0.6rem);
}
[open] .c-menu__icon .line.bot {
  transform: rotate(-45deg);
}
.c-menu__details {
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  overflow: auto;
}

@media only screen and (min-width: 768px) {
  .c-menu {
    display: none;
  }
}
.c-table__wrapper {
  max-width: 100%;
  overflow-x: auto;
}

.u-rtxt a:not([class]) {
  text-decoration: underline;
}
.u-rtxt a:not([class]):hover {
  text-decoration: none;
}

.u-m-auto {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
}
.u-m-0 {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}
.u-m-8 {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
}
.u-m-16 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
  margin-left: 1.6rem;
  margin-right: 1.6rem;
}
.u-m-24 {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
  margin-left: 2.4rem;
  margin-right: 2.4rem;
}
.u-m-32 {
  margin-top: 3.2rem;
  margin-bottom: 3.2rem;
  margin-left: 3.2rem;
  margin-right: 3.2rem;
}
.u-m-40 {
  margin-top: 4rem;
  margin-bottom: 4rem;
  margin-left: 4rem;
  margin-right: 4rem;
}
.u-m-48 {
  margin-top: 4.8rem;
  margin-bottom: 4.8rem;
  margin-left: 4.8rem;
  margin-right: 4.8rem;
}
.u-m-56 {
  margin-top: 5.6rem;
  margin-bottom: 5.6rem;
  margin-left: 5.6rem;
  margin-right: 5.6rem;
}
.u-m-64 {
  margin-top: 6.4rem;
  margin-bottom: 6.4rem;
  margin-left: 6.4rem;
  margin-right: 6.4rem;
}
.u-m-72 {
  margin-top: 7.2rem;
  margin-bottom: 7.2rem;
  margin-left: 7.2rem;
  margin-right: 7.2rem;
}
.u-m-80 {
  margin-top: 8rem;
  margin-bottom: 8rem;
  margin-left: 8rem;
  margin-right: 8rem;
}
.u-m-88 {
  margin-top: 8.8rem;
  margin-bottom: 8.8rem;
  margin-left: 8.8rem;
  margin-right: 8.8rem;
}
.u-m-96 {
  margin-top: 9.6rem;
  margin-bottom: 9.6rem;
  margin-left: 9.6rem;
  margin-right: 9.6rem;
}

.u-mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.u-mx-0 {
  margin-left: 0;
  margin-right: 0;
}
.u-mx-8 {
  margin-left: 0.8rem;
  margin-right: 0.8rem;
}
.u-mx-16 {
  margin-left: 1.6rem;
  margin-right: 1.6rem;
}
.u-mx-24 {
  margin-left: 2.4rem;
  margin-right: 2.4rem;
}
.u-mx-32 {
  margin-left: 3.2rem;
  margin-right: 3.2rem;
}
.u-mx-40 {
  margin-left: 4rem;
  margin-right: 4rem;
}
.u-mx-48 {
  margin-left: 4.8rem;
  margin-right: 4.8rem;
}
.u-mx-56 {
  margin-left: 5.6rem;
  margin-right: 5.6rem;
}
.u-mx-64 {
  margin-left: 6.4rem;
  margin-right: 6.4rem;
}
.u-mx-72 {
  margin-left: 7.2rem;
  margin-right: 7.2rem;
}
.u-mx-80 {
  margin-left: 8rem;
  margin-right: 8rem;
}
.u-mx-88 {
  margin-left: 8.8rem;
  margin-right: 8.8rem;
}
.u-mx-96 {
  margin-left: 9.6rem;
  margin-right: 9.6rem;
}

.u-ml-auto {
  margin-left: auto;
}
.u-ml-0 {
  margin-left: 0;
}
.u-ml-8 {
  margin-left: 0.8rem;
}
.u-ml-16 {
  margin-left: 1.6rem;
}
.u-ml-24 {
  margin-left: 2.4rem;
}
.u-ml-32 {
  margin-left: 3.2rem;
}
.u-ml-40 {
  margin-left: 4rem;
}
.u-ml-48 {
  margin-left: 4.8rem;
}
.u-ml-56 {
  margin-left: 5.6rem;
}
.u-ml-64 {
  margin-left: 6.4rem;
}
.u-ml-72 {
  margin-left: 7.2rem;
}
.u-ml-80 {
  margin-left: 8rem;
}
.u-ml-88 {
  margin-left: 8.8rem;
}
.u-ml-96 {
  margin-left: 9.6rem;
}

.u-mr-auto {
  margin-right: auto;
}
.u-mr-0 {
  margin-right: 0;
}
.u-mr-8 {
  margin-right: 0.8rem;
}
.u-mr-16 {
  margin-right: 1.6rem;
}
.u-mr-24 {
  margin-right: 2.4rem;
}
.u-mr-32 {
  margin-right: 3.2rem;
}
.u-mr-40 {
  margin-right: 4rem;
}
.u-mr-48 {
  margin-right: 4.8rem;
}
.u-mr-56 {
  margin-right: 5.6rem;
}
.u-mr-64 {
  margin-right: 6.4rem;
}
.u-mr-72 {
  margin-right: 7.2rem;
}
.u-mr-80 {
  margin-right: 8rem;
}
.u-mr-88 {
  margin-right: 8.8rem;
}
.u-mr-96 {
  margin-right: 9.6rem;
}

.u-my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.u-my-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.u-my-8 {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}
.u-my-16 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
}
.u-my-24 {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
}
.u-my-32 {
  margin-top: 3.2rem;
  margin-bottom: 3.2rem;
}
.u-my-40 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.u-my-48 {
  margin-top: 4.8rem;
  margin-bottom: 4.8rem;
}
.u-my-56 {
  margin-top: 5.6rem;
  margin-bottom: 5.6rem;
}
.u-my-64 {
  margin-top: 6.4rem;
  margin-bottom: 6.4rem;
}
.u-my-72 {
  margin-top: 7.2rem;
  margin-bottom: 7.2rem;
}
.u-my-80 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}
.u-my-88 {
  margin-top: 8.8rem;
  margin-bottom: 8.8rem;
}
.u-my-96 {
  margin-top: 9.6rem;
  margin-bottom: 9.6rem;
}

.u-mt-auto {
  margin-top: auto;
}
.u-mt-0 {
  margin-top: 0;
}
.u-mt-8 {
  margin-top: 0.8rem;
}
.u-mt-16 {
  margin-top: 1.6rem;
}
.u-mt-24 {
  margin-top: 2.4rem;
}
.u-mt-32 {
  margin-top: 3.2rem;
}
.u-mt-40 {
  margin-top: 4rem;
}
.u-mt-48 {
  margin-top: 4.8rem;
}
.u-mt-56 {
  margin-top: 5.6rem;
}
.u-mt-64 {
  margin-top: 6.4rem;
}
.u-mt-72 {
  margin-top: 7.2rem;
}
.u-mt-80 {
  margin-top: 8rem;
}
.u-mt-88 {
  margin-top: 8.8rem;
}
.u-mt-96 {
  margin-top: 9.6rem;
}

.u-mb-auto {
  margin-bottom: auto;
}
.u-mb-0 {
  margin-bottom: 0;
}
.u-mb-8 {
  margin-bottom: 0.8rem;
}
.u-mb-16 {
  margin-bottom: 1.6rem;
}
.u-mb-24 {
  margin-bottom: 2.4rem;
}
.u-mb-32 {
  margin-bottom: 3.2rem;
}
.u-mb-40 {
  margin-bottom: 4rem;
}
.u-mb-48 {
  margin-bottom: 4.8rem;
}
.u-mb-56 {
  margin-bottom: 5.6rem;
}
.u-mb-64 {
  margin-bottom: 6.4rem;
}
.u-mb-72 {
  margin-bottom: 7.2rem;
}
.u-mb-80 {
  margin-bottom: 8rem;
}
.u-mb-88 {
  margin-bottom: 8.8rem;
}
.u-mb-96 {
  margin-bottom: 9.6rem;
}

@media only screen and (min-width: 480px) {
  .u-m-auto--xs {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .u-m-0--xs {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .u-m-8--xs {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .u-m-16--xs {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .u-m-24--xs {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .u-m-32--xs {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
    margin-left: 3.2rem;
    margin-right: 3.2rem;
  }
  .u-m-40--xs {
    margin-top: 4rem;
    margin-bottom: 4rem;
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .u-m-48--xs {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
  .u-m-56--xs {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem;
    margin-left: 5.6rem;
    margin-right: 5.6rem;
  }
  .u-m-64--xs {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem;
    margin-left: 6.4rem;
    margin-right: 6.4rem;
  }
  .u-m-72--xs {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem;
    margin-left: 7.2rem;
    margin-right: 7.2rem;
  }
  .u-m-80--xs {
    margin-top: 8rem;
    margin-bottom: 8rem;
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .u-m-88--xs {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem;
    margin-left: 8.8rem;
    margin-right: 8.8rem;
  }
  .u-m-96--xs {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem;
    margin-left: 9.6rem;
    margin-right: 9.6rem;
  }
  .u-mx-auto--xs {
    margin-left: auto;
    margin-right: auto;
  }
  .u-mx-0--xs {
    margin-left: 0;
    margin-right: 0;
  }
  .u-mx-8--xs {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .u-mx-16--xs {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .u-mx-24--xs {
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .u-mx-32--xs {
    margin-left: 3.2rem;
    margin-right: 3.2rem;
  }
  .u-mx-40--xs {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .u-mx-48--xs {
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
  .u-mx-56--xs {
    margin-left: 5.6rem;
    margin-right: 5.6rem;
  }
  .u-mx-64--xs {
    margin-left: 6.4rem;
    margin-right: 6.4rem;
  }
  .u-mx-72--xs {
    margin-left: 7.2rem;
    margin-right: 7.2rem;
  }
  .u-mx-80--xs {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .u-mx-88--xs {
    margin-left: 8.8rem;
    margin-right: 8.8rem;
  }
  .u-mx-96--xs {
    margin-left: 9.6rem;
    margin-right: 9.6rem;
  }
  .u-ml-auto--xs {
    margin-left: auto;
  }
  .u-ml-0--xs {
    margin-left: 0;
  }
  .u-ml-8--xs {
    margin-left: 0.8rem;
  }
  .u-ml-16--xs {
    margin-left: 1.6rem;
  }
  .u-ml-24--xs {
    margin-left: 2.4rem;
  }
  .u-ml-32--xs {
    margin-left: 3.2rem;
  }
  .u-ml-40--xs {
    margin-left: 4rem;
  }
  .u-ml-48--xs {
    margin-left: 4.8rem;
  }
  .u-ml-56--xs {
    margin-left: 5.6rem;
  }
  .u-ml-64--xs {
    margin-left: 6.4rem;
  }
  .u-ml-72--xs {
    margin-left: 7.2rem;
  }
  .u-ml-80--xs {
    margin-left: 8rem;
  }
  .u-ml-88--xs {
    margin-left: 8.8rem;
  }
  .u-ml-96--xs {
    margin-left: 9.6rem;
  }
  .u-mr-auto--xs {
    margin-right: auto;
  }
  .u-mr-0--xs {
    margin-right: 0;
  }
  .u-mr-8--xs {
    margin-right: 0.8rem;
  }
  .u-mr-16--xs {
    margin-right: 1.6rem;
  }
  .u-mr-24--xs {
    margin-right: 2.4rem;
  }
  .u-mr-32--xs {
    margin-right: 3.2rem;
  }
  .u-mr-40--xs {
    margin-right: 4rem;
  }
  .u-mr-48--xs {
    margin-right: 4.8rem;
  }
  .u-mr-56--xs {
    margin-right: 5.6rem;
  }
  .u-mr-64--xs {
    margin-right: 6.4rem;
  }
  .u-mr-72--xs {
    margin-right: 7.2rem;
  }
  .u-mr-80--xs {
    margin-right: 8rem;
  }
  .u-mr-88--xs {
    margin-right: 8.8rem;
  }
  .u-mr-96--xs {
    margin-right: 9.6rem;
  }
  .u-my-auto--xs {
    margin-top: auto;
    margin-bottom: auto;
  }
  .u-my-0--xs {
    margin-top: 0;
    margin-bottom: 0;
  }
  .u-my-8--xs {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
  .u-my-16--xs {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
  }
  .u-my-24--xs {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
  }
  .u-my-32--xs {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
  }
  .u-my-40--xs {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .u-my-48--xs {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
  }
  .u-my-56--xs {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem;
  }
  .u-my-64--xs {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem;
  }
  .u-my-72--xs {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem;
  }
  .u-my-80--xs {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .u-my-88--xs {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem;
  }
  .u-my-96--xs {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem;
  }
  .u-mt-auto--xs {
    margin-top: auto;
  }
  .u-mt-0--xs {
    margin-top: 0;
  }
  .u-mt-8--xs {
    margin-top: 0.8rem;
  }
  .u-mt-16--xs {
    margin-top: 1.6rem;
  }
  .u-mt-24--xs {
    margin-top: 2.4rem;
  }
  .u-mt-32--xs {
    margin-top: 3.2rem;
  }
  .u-mt-40--xs {
    margin-top: 4rem;
  }
  .u-mt-48--xs {
    margin-top: 4.8rem;
  }
  .u-mt-56--xs {
    margin-top: 5.6rem;
  }
  .u-mt-64--xs {
    margin-top: 6.4rem;
  }
  .u-mt-72--xs {
    margin-top: 7.2rem;
  }
  .u-mt-80--xs {
    margin-top: 8rem;
  }
  .u-mt-88--xs {
    margin-top: 8.8rem;
  }
  .u-mt-96--xs {
    margin-top: 9.6rem;
  }
  .u-mb-auto--xs {
    margin-bottom: auto;
  }
  .u-mb-0--xs {
    margin-bottom: 0;
  }
  .u-mb-8--xs {
    margin-bottom: 0.8rem;
  }
  .u-mb-16--xs {
    margin-bottom: 1.6rem;
  }
  .u-mb-24--xs {
    margin-bottom: 2.4rem;
  }
  .u-mb-32--xs {
    margin-bottom: 3.2rem;
  }
  .u-mb-40--xs {
    margin-bottom: 4rem;
  }
  .u-mb-48--xs {
    margin-bottom: 4.8rem;
  }
  .u-mb-56--xs {
    margin-bottom: 5.6rem;
  }
  .u-mb-64--xs {
    margin-bottom: 6.4rem;
  }
  .u-mb-72--xs {
    margin-bottom: 7.2rem;
  }
  .u-mb-80--xs {
    margin-bottom: 8rem;
  }
  .u-mb-88--xs {
    margin-bottom: 8.8rem;
  }
  .u-mb-96--xs {
    margin-bottom: 9.6rem;
  }
}
@media only screen and (min-width: 640px) {
  .u-m-auto--sm {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .u-m-0--sm {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .u-m-8--sm {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .u-m-16--sm {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .u-m-24--sm {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .u-m-32--sm {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
    margin-left: 3.2rem;
    margin-right: 3.2rem;
  }
  .u-m-40--sm {
    margin-top: 4rem;
    margin-bottom: 4rem;
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .u-m-48--sm {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
  .u-m-56--sm {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem;
    margin-left: 5.6rem;
    margin-right: 5.6rem;
  }
  .u-m-64--sm {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem;
    margin-left: 6.4rem;
    margin-right: 6.4rem;
  }
  .u-m-72--sm {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem;
    margin-left: 7.2rem;
    margin-right: 7.2rem;
  }
  .u-m-80--sm {
    margin-top: 8rem;
    margin-bottom: 8rem;
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .u-m-88--sm {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem;
    margin-left: 8.8rem;
    margin-right: 8.8rem;
  }
  .u-m-96--sm {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem;
    margin-left: 9.6rem;
    margin-right: 9.6rem;
  }
  .u-mx-auto--sm {
    margin-left: auto;
    margin-right: auto;
  }
  .u-mx-0--sm {
    margin-left: 0;
    margin-right: 0;
  }
  .u-mx-8--sm {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .u-mx-16--sm {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .u-mx-24--sm {
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .u-mx-32--sm {
    margin-left: 3.2rem;
    margin-right: 3.2rem;
  }
  .u-mx-40--sm {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .u-mx-48--sm {
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
  .u-mx-56--sm {
    margin-left: 5.6rem;
    margin-right: 5.6rem;
  }
  .u-mx-64--sm {
    margin-left: 6.4rem;
    margin-right: 6.4rem;
  }
  .u-mx-72--sm {
    margin-left: 7.2rem;
    margin-right: 7.2rem;
  }
  .u-mx-80--sm {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .u-mx-88--sm {
    margin-left: 8.8rem;
    margin-right: 8.8rem;
  }
  .u-mx-96--sm {
    margin-left: 9.6rem;
    margin-right: 9.6rem;
  }
  .u-ml-auto--sm {
    margin-left: auto;
  }
  .u-ml-0--sm {
    margin-left: 0;
  }
  .u-ml-8--sm {
    margin-left: 0.8rem;
  }
  .u-ml-16--sm {
    margin-left: 1.6rem;
  }
  .u-ml-24--sm {
    margin-left: 2.4rem;
  }
  .u-ml-32--sm {
    margin-left: 3.2rem;
  }
  .u-ml-40--sm {
    margin-left: 4rem;
  }
  .u-ml-48--sm {
    margin-left: 4.8rem;
  }
  .u-ml-56--sm {
    margin-left: 5.6rem;
  }
  .u-ml-64--sm {
    margin-left: 6.4rem;
  }
  .u-ml-72--sm {
    margin-left: 7.2rem;
  }
  .u-ml-80--sm {
    margin-left: 8rem;
  }
  .u-ml-88--sm {
    margin-left: 8.8rem;
  }
  .u-ml-96--sm {
    margin-left: 9.6rem;
  }
  .u-mr-auto--sm {
    margin-right: auto;
  }
  .u-mr-0--sm {
    margin-right: 0;
  }
  .u-mr-8--sm {
    margin-right: 0.8rem;
  }
  .u-mr-16--sm {
    margin-right: 1.6rem;
  }
  .u-mr-24--sm {
    margin-right: 2.4rem;
  }
  .u-mr-32--sm {
    margin-right: 3.2rem;
  }
  .u-mr-40--sm {
    margin-right: 4rem;
  }
  .u-mr-48--sm {
    margin-right: 4.8rem;
  }
  .u-mr-56--sm {
    margin-right: 5.6rem;
  }
  .u-mr-64--sm {
    margin-right: 6.4rem;
  }
  .u-mr-72--sm {
    margin-right: 7.2rem;
  }
  .u-mr-80--sm {
    margin-right: 8rem;
  }
  .u-mr-88--sm {
    margin-right: 8.8rem;
  }
  .u-mr-96--sm {
    margin-right: 9.6rem;
  }
  .u-my-auto--sm {
    margin-top: auto;
    margin-bottom: auto;
  }
  .u-my-0--sm {
    margin-top: 0;
    margin-bottom: 0;
  }
  .u-my-8--sm {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
  .u-my-16--sm {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
  }
  .u-my-24--sm {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
  }
  .u-my-32--sm {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
  }
  .u-my-40--sm {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .u-my-48--sm {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
  }
  .u-my-56--sm {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem;
  }
  .u-my-64--sm {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem;
  }
  .u-my-72--sm {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem;
  }
  .u-my-80--sm {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .u-my-88--sm {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem;
  }
  .u-my-96--sm {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem;
  }
  .u-mt-auto--sm {
    margin-top: auto;
  }
  .u-mt-0--sm {
    margin-top: 0;
  }
  .u-mt-8--sm {
    margin-top: 0.8rem;
  }
  .u-mt-16--sm {
    margin-top: 1.6rem;
  }
  .u-mt-24--sm {
    margin-top: 2.4rem;
  }
  .u-mt-32--sm {
    margin-top: 3.2rem;
  }
  .u-mt-40--sm {
    margin-top: 4rem;
  }
  .u-mt-48--sm {
    margin-top: 4.8rem;
  }
  .u-mt-56--sm {
    margin-top: 5.6rem;
  }
  .u-mt-64--sm {
    margin-top: 6.4rem;
  }
  .u-mt-72--sm {
    margin-top: 7.2rem;
  }
  .u-mt-80--sm {
    margin-top: 8rem;
  }
  .u-mt-88--sm {
    margin-top: 8.8rem;
  }
  .u-mt-96--sm {
    margin-top: 9.6rem;
  }
  .u-mb-auto--sm {
    margin-bottom: auto;
  }
  .u-mb-0--sm {
    margin-bottom: 0;
  }
  .u-mb-8--sm {
    margin-bottom: 0.8rem;
  }
  .u-mb-16--sm {
    margin-bottom: 1.6rem;
  }
  .u-mb-24--sm {
    margin-bottom: 2.4rem;
  }
  .u-mb-32--sm {
    margin-bottom: 3.2rem;
  }
  .u-mb-40--sm {
    margin-bottom: 4rem;
  }
  .u-mb-48--sm {
    margin-bottom: 4.8rem;
  }
  .u-mb-56--sm {
    margin-bottom: 5.6rem;
  }
  .u-mb-64--sm {
    margin-bottom: 6.4rem;
  }
  .u-mb-72--sm {
    margin-bottom: 7.2rem;
  }
  .u-mb-80--sm {
    margin-bottom: 8rem;
  }
  .u-mb-88--sm {
    margin-bottom: 8.8rem;
  }
  .u-mb-96--sm {
    margin-bottom: 9.6rem;
  }
}
@media only screen and (min-width: 768px) {
  .u-m-auto--md {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .u-m-0--md {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .u-m-8--md {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .u-m-16--md {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .u-m-24--md {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .u-m-32--md {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
    margin-left: 3.2rem;
    margin-right: 3.2rem;
  }
  .u-m-40--md {
    margin-top: 4rem;
    margin-bottom: 4rem;
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .u-m-48--md {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
  .u-m-56--md {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem;
    margin-left: 5.6rem;
    margin-right: 5.6rem;
  }
  .u-m-64--md {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem;
    margin-left: 6.4rem;
    margin-right: 6.4rem;
  }
  .u-m-72--md {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem;
    margin-left: 7.2rem;
    margin-right: 7.2rem;
  }
  .u-m-80--md {
    margin-top: 8rem;
    margin-bottom: 8rem;
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .u-m-88--md {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem;
    margin-left: 8.8rem;
    margin-right: 8.8rem;
  }
  .u-m-96--md {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem;
    margin-left: 9.6rem;
    margin-right: 9.6rem;
  }
  .u-mx-auto--md {
    margin-left: auto;
    margin-right: auto;
  }
  .u-mx-0--md {
    margin-left: 0;
    margin-right: 0;
  }
  .u-mx-8--md {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .u-mx-16--md {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .u-mx-24--md {
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .u-mx-32--md {
    margin-left: 3.2rem;
    margin-right: 3.2rem;
  }
  .u-mx-40--md {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .u-mx-48--md {
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
  .u-mx-56--md {
    margin-left: 5.6rem;
    margin-right: 5.6rem;
  }
  .u-mx-64--md {
    margin-left: 6.4rem;
    margin-right: 6.4rem;
  }
  .u-mx-72--md {
    margin-left: 7.2rem;
    margin-right: 7.2rem;
  }
  .u-mx-80--md {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .u-mx-88--md {
    margin-left: 8.8rem;
    margin-right: 8.8rem;
  }
  .u-mx-96--md {
    margin-left: 9.6rem;
    margin-right: 9.6rem;
  }
  .u-ml-auto--md {
    margin-left: auto;
  }
  .u-ml-0--md {
    margin-left: 0;
  }
  .u-ml-8--md {
    margin-left: 0.8rem;
  }
  .u-ml-16--md {
    margin-left: 1.6rem;
  }
  .u-ml-24--md {
    margin-left: 2.4rem;
  }
  .u-ml-32--md {
    margin-left: 3.2rem;
  }
  .u-ml-40--md {
    margin-left: 4rem;
  }
  .u-ml-48--md {
    margin-left: 4.8rem;
  }
  .u-ml-56--md {
    margin-left: 5.6rem;
  }
  .u-ml-64--md {
    margin-left: 6.4rem;
  }
  .u-ml-72--md {
    margin-left: 7.2rem;
  }
  .u-ml-80--md {
    margin-left: 8rem;
  }
  .u-ml-88--md {
    margin-left: 8.8rem;
  }
  .u-ml-96--md {
    margin-left: 9.6rem;
  }
  .u-mr-auto--md {
    margin-right: auto;
  }
  .u-mr-0--md {
    margin-right: 0;
  }
  .u-mr-8--md {
    margin-right: 0.8rem;
  }
  .u-mr-16--md {
    margin-right: 1.6rem;
  }
  .u-mr-24--md {
    margin-right: 2.4rem;
  }
  .u-mr-32--md {
    margin-right: 3.2rem;
  }
  .u-mr-40--md {
    margin-right: 4rem;
  }
  .u-mr-48--md {
    margin-right: 4.8rem;
  }
  .u-mr-56--md {
    margin-right: 5.6rem;
  }
  .u-mr-64--md {
    margin-right: 6.4rem;
  }
  .u-mr-72--md {
    margin-right: 7.2rem;
  }
  .u-mr-80--md {
    margin-right: 8rem;
  }
  .u-mr-88--md {
    margin-right: 8.8rem;
  }
  .u-mr-96--md {
    margin-right: 9.6rem;
  }
  .u-my-auto--md {
    margin-top: auto;
    margin-bottom: auto;
  }
  .u-my-0--md {
    margin-top: 0;
    margin-bottom: 0;
  }
  .u-my-8--md {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
  .u-my-16--md {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
  }
  .u-my-24--md {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
  }
  .u-my-32--md {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
  }
  .u-my-40--md {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .u-my-48--md {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
  }
  .u-my-56--md {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem;
  }
  .u-my-64--md {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem;
  }
  .u-my-72--md {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem;
  }
  .u-my-80--md {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .u-my-88--md {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem;
  }
  .u-my-96--md {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem;
  }
  .u-mt-auto--md {
    margin-top: auto;
  }
  .u-mt-0--md {
    margin-top: 0;
  }
  .u-mt-8--md {
    margin-top: 0.8rem;
  }
  .u-mt-16--md {
    margin-top: 1.6rem;
  }
  .u-mt-24--md {
    margin-top: 2.4rem;
  }
  .u-mt-32--md {
    margin-top: 3.2rem;
  }
  .u-mt-40--md {
    margin-top: 4rem;
  }
  .u-mt-48--md {
    margin-top: 4.8rem;
  }
  .u-mt-56--md {
    margin-top: 5.6rem;
  }
  .u-mt-64--md {
    margin-top: 6.4rem;
  }
  .u-mt-72--md {
    margin-top: 7.2rem;
  }
  .u-mt-80--md {
    margin-top: 8rem;
  }
  .u-mt-88--md {
    margin-top: 8.8rem;
  }
  .u-mt-96--md {
    margin-top: 9.6rem;
  }
  .u-mb-auto--md {
    margin-bottom: auto;
  }
  .u-mb-0--md {
    margin-bottom: 0;
  }
  .u-mb-8--md {
    margin-bottom: 0.8rem;
  }
  .u-mb-16--md {
    margin-bottom: 1.6rem;
  }
  .u-mb-24--md {
    margin-bottom: 2.4rem;
  }
  .u-mb-32--md {
    margin-bottom: 3.2rem;
  }
  .u-mb-40--md {
    margin-bottom: 4rem;
  }
  .u-mb-48--md {
    margin-bottom: 4.8rem;
  }
  .u-mb-56--md {
    margin-bottom: 5.6rem;
  }
  .u-mb-64--md {
    margin-bottom: 6.4rem;
  }
  .u-mb-72--md {
    margin-bottom: 7.2rem;
  }
  .u-mb-80--md {
    margin-bottom: 8rem;
  }
  .u-mb-88--md {
    margin-bottom: 8.8rem;
  }
  .u-mb-96--md {
    margin-bottom: 9.6rem;
  }
}
@media only screen and (min-width: 1024px) {
  .u-m-auto--lg {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .u-m-0--lg {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .u-m-8--lg {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .u-m-16--lg {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .u-m-24--lg {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .u-m-32--lg {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
    margin-left: 3.2rem;
    margin-right: 3.2rem;
  }
  .u-m-40--lg {
    margin-top: 4rem;
    margin-bottom: 4rem;
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .u-m-48--lg {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
  .u-m-56--lg {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem;
    margin-left: 5.6rem;
    margin-right: 5.6rem;
  }
  .u-m-64--lg {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem;
    margin-left: 6.4rem;
    margin-right: 6.4rem;
  }
  .u-m-72--lg {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem;
    margin-left: 7.2rem;
    margin-right: 7.2rem;
  }
  .u-m-80--lg {
    margin-top: 8rem;
    margin-bottom: 8rem;
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .u-m-88--lg {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem;
    margin-left: 8.8rem;
    margin-right: 8.8rem;
  }
  .u-m-96--lg {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem;
    margin-left: 9.6rem;
    margin-right: 9.6rem;
  }
  .u-mx-auto--lg {
    margin-left: auto;
    margin-right: auto;
  }
  .u-mx-0--lg {
    margin-left: 0;
    margin-right: 0;
  }
  .u-mx-8--lg {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .u-mx-16--lg {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .u-mx-24--lg {
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .u-mx-32--lg {
    margin-left: 3.2rem;
    margin-right: 3.2rem;
  }
  .u-mx-40--lg {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .u-mx-48--lg {
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
  .u-mx-56--lg {
    margin-left: 5.6rem;
    margin-right: 5.6rem;
  }
  .u-mx-64--lg {
    margin-left: 6.4rem;
    margin-right: 6.4rem;
  }
  .u-mx-72--lg {
    margin-left: 7.2rem;
    margin-right: 7.2rem;
  }
  .u-mx-80--lg {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .u-mx-88--lg {
    margin-left: 8.8rem;
    margin-right: 8.8rem;
  }
  .u-mx-96--lg {
    margin-left: 9.6rem;
    margin-right: 9.6rem;
  }
  .u-ml-auto--lg {
    margin-left: auto;
  }
  .u-ml-0--lg {
    margin-left: 0;
  }
  .u-ml-8--lg {
    margin-left: 0.8rem;
  }
  .u-ml-16--lg {
    margin-left: 1.6rem;
  }
  .u-ml-24--lg {
    margin-left: 2.4rem;
  }
  .u-ml-32--lg {
    margin-left: 3.2rem;
  }
  .u-ml-40--lg {
    margin-left: 4rem;
  }
  .u-ml-48--lg {
    margin-left: 4.8rem;
  }
  .u-ml-56--lg {
    margin-left: 5.6rem;
  }
  .u-ml-64--lg {
    margin-left: 6.4rem;
  }
  .u-ml-72--lg {
    margin-left: 7.2rem;
  }
  .u-ml-80--lg {
    margin-left: 8rem;
  }
  .u-ml-88--lg {
    margin-left: 8.8rem;
  }
  .u-ml-96--lg {
    margin-left: 9.6rem;
  }
  .u-mr-auto--lg {
    margin-right: auto;
  }
  .u-mr-0--lg {
    margin-right: 0;
  }
  .u-mr-8--lg {
    margin-right: 0.8rem;
  }
  .u-mr-16--lg {
    margin-right: 1.6rem;
  }
  .u-mr-24--lg {
    margin-right: 2.4rem;
  }
  .u-mr-32--lg {
    margin-right: 3.2rem;
  }
  .u-mr-40--lg {
    margin-right: 4rem;
  }
  .u-mr-48--lg {
    margin-right: 4.8rem;
  }
  .u-mr-56--lg {
    margin-right: 5.6rem;
  }
  .u-mr-64--lg {
    margin-right: 6.4rem;
  }
  .u-mr-72--lg {
    margin-right: 7.2rem;
  }
  .u-mr-80--lg {
    margin-right: 8rem;
  }
  .u-mr-88--lg {
    margin-right: 8.8rem;
  }
  .u-mr-96--lg {
    margin-right: 9.6rem;
  }
  .u-my-auto--lg {
    margin-top: auto;
    margin-bottom: auto;
  }
  .u-my-0--lg {
    margin-top: 0;
    margin-bottom: 0;
  }
  .u-my-8--lg {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
  .u-my-16--lg {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
  }
  .u-my-24--lg {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
  }
  .u-my-32--lg {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
  }
  .u-my-40--lg {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .u-my-48--lg {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
  }
  .u-my-56--lg {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem;
  }
  .u-my-64--lg {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem;
  }
  .u-my-72--lg {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem;
  }
  .u-my-80--lg {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .u-my-88--lg {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem;
  }
  .u-my-96--lg {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem;
  }
  .u-mt-auto--lg {
    margin-top: auto;
  }
  .u-mt-0--lg {
    margin-top: 0;
  }
  .u-mt-8--lg {
    margin-top: 0.8rem;
  }
  .u-mt-16--lg {
    margin-top: 1.6rem;
  }
  .u-mt-24--lg {
    margin-top: 2.4rem;
  }
  .u-mt-32--lg {
    margin-top: 3.2rem;
  }
  .u-mt-40--lg {
    margin-top: 4rem;
  }
  .u-mt-48--lg {
    margin-top: 4.8rem;
  }
  .u-mt-56--lg {
    margin-top: 5.6rem;
  }
  .u-mt-64--lg {
    margin-top: 6.4rem;
  }
  .u-mt-72--lg {
    margin-top: 7.2rem;
  }
  .u-mt-80--lg {
    margin-top: 8rem;
  }
  .u-mt-88--lg {
    margin-top: 8.8rem;
  }
  .u-mt-96--lg {
    margin-top: 9.6rem;
  }
  .u-mb-auto--lg {
    margin-bottom: auto;
  }
  .u-mb-0--lg {
    margin-bottom: 0;
  }
  .u-mb-8--lg {
    margin-bottom: 0.8rem;
  }
  .u-mb-16--lg {
    margin-bottom: 1.6rem;
  }
  .u-mb-24--lg {
    margin-bottom: 2.4rem;
  }
  .u-mb-32--lg {
    margin-bottom: 3.2rem;
  }
  .u-mb-40--lg {
    margin-bottom: 4rem;
  }
  .u-mb-48--lg {
    margin-bottom: 4.8rem;
  }
  .u-mb-56--lg {
    margin-bottom: 5.6rem;
  }
  .u-mb-64--lg {
    margin-bottom: 6.4rem;
  }
  .u-mb-72--lg {
    margin-bottom: 7.2rem;
  }
  .u-mb-80--lg {
    margin-bottom: 8rem;
  }
  .u-mb-88--lg {
    margin-bottom: 8.8rem;
  }
  .u-mb-96--lg {
    margin-bottom: 9.6rem;
  }
}
@media only screen and (min-width: 1280px) {
  .u-m-auto--xl {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .u-m-0--xl {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .u-m-8--xl {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .u-m-16--xl {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .u-m-24--xl {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .u-m-32--xl {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
    margin-left: 3.2rem;
    margin-right: 3.2rem;
  }
  .u-m-40--xl {
    margin-top: 4rem;
    margin-bottom: 4rem;
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .u-m-48--xl {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
  .u-m-56--xl {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem;
    margin-left: 5.6rem;
    margin-right: 5.6rem;
  }
  .u-m-64--xl {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem;
    margin-left: 6.4rem;
    margin-right: 6.4rem;
  }
  .u-m-72--xl {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem;
    margin-left: 7.2rem;
    margin-right: 7.2rem;
  }
  .u-m-80--xl {
    margin-top: 8rem;
    margin-bottom: 8rem;
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .u-m-88--xl {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem;
    margin-left: 8.8rem;
    margin-right: 8.8rem;
  }
  .u-m-96--xl {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem;
    margin-left: 9.6rem;
    margin-right: 9.6rem;
  }
  .u-mx-auto--xl {
    margin-left: auto;
    margin-right: auto;
  }
  .u-mx-0--xl {
    margin-left: 0;
    margin-right: 0;
  }
  .u-mx-8--xl {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .u-mx-16--xl {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .u-mx-24--xl {
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .u-mx-32--xl {
    margin-left: 3.2rem;
    margin-right: 3.2rem;
  }
  .u-mx-40--xl {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .u-mx-48--xl {
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
  .u-mx-56--xl {
    margin-left: 5.6rem;
    margin-right: 5.6rem;
  }
  .u-mx-64--xl {
    margin-left: 6.4rem;
    margin-right: 6.4rem;
  }
  .u-mx-72--xl {
    margin-left: 7.2rem;
    margin-right: 7.2rem;
  }
  .u-mx-80--xl {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .u-mx-88--xl {
    margin-left: 8.8rem;
    margin-right: 8.8rem;
  }
  .u-mx-96--xl {
    margin-left: 9.6rem;
    margin-right: 9.6rem;
  }
  .u-ml-auto--xl {
    margin-left: auto;
  }
  .u-ml-0--xl {
    margin-left: 0;
  }
  .u-ml-8--xl {
    margin-left: 0.8rem;
  }
  .u-ml-16--xl {
    margin-left: 1.6rem;
  }
  .u-ml-24--xl {
    margin-left: 2.4rem;
  }
  .u-ml-32--xl {
    margin-left: 3.2rem;
  }
  .u-ml-40--xl {
    margin-left: 4rem;
  }
  .u-ml-48--xl {
    margin-left: 4.8rem;
  }
  .u-ml-56--xl {
    margin-left: 5.6rem;
  }
  .u-ml-64--xl {
    margin-left: 6.4rem;
  }
  .u-ml-72--xl {
    margin-left: 7.2rem;
  }
  .u-ml-80--xl {
    margin-left: 8rem;
  }
  .u-ml-88--xl {
    margin-left: 8.8rem;
  }
  .u-ml-96--xl {
    margin-left: 9.6rem;
  }
  .u-mr-auto--xl {
    margin-right: auto;
  }
  .u-mr-0--xl {
    margin-right: 0;
  }
  .u-mr-8--xl {
    margin-right: 0.8rem;
  }
  .u-mr-16--xl {
    margin-right: 1.6rem;
  }
  .u-mr-24--xl {
    margin-right: 2.4rem;
  }
  .u-mr-32--xl {
    margin-right: 3.2rem;
  }
  .u-mr-40--xl {
    margin-right: 4rem;
  }
  .u-mr-48--xl {
    margin-right: 4.8rem;
  }
  .u-mr-56--xl {
    margin-right: 5.6rem;
  }
  .u-mr-64--xl {
    margin-right: 6.4rem;
  }
  .u-mr-72--xl {
    margin-right: 7.2rem;
  }
  .u-mr-80--xl {
    margin-right: 8rem;
  }
  .u-mr-88--xl {
    margin-right: 8.8rem;
  }
  .u-mr-96--xl {
    margin-right: 9.6rem;
  }
  .u-my-auto--xl {
    margin-top: auto;
    margin-bottom: auto;
  }
  .u-my-0--xl {
    margin-top: 0;
    margin-bottom: 0;
  }
  .u-my-8--xl {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
  .u-my-16--xl {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
  }
  .u-my-24--xl {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
  }
  .u-my-32--xl {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
  }
  .u-my-40--xl {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .u-my-48--xl {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
  }
  .u-my-56--xl {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem;
  }
  .u-my-64--xl {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem;
  }
  .u-my-72--xl {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem;
  }
  .u-my-80--xl {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .u-my-88--xl {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem;
  }
  .u-my-96--xl {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem;
  }
  .u-mt-auto--xl {
    margin-top: auto;
  }
  .u-mt-0--xl {
    margin-top: 0;
  }
  .u-mt-8--xl {
    margin-top: 0.8rem;
  }
  .u-mt-16--xl {
    margin-top: 1.6rem;
  }
  .u-mt-24--xl {
    margin-top: 2.4rem;
  }
  .u-mt-32--xl {
    margin-top: 3.2rem;
  }
  .u-mt-40--xl {
    margin-top: 4rem;
  }
  .u-mt-48--xl {
    margin-top: 4.8rem;
  }
  .u-mt-56--xl {
    margin-top: 5.6rem;
  }
  .u-mt-64--xl {
    margin-top: 6.4rem;
  }
  .u-mt-72--xl {
    margin-top: 7.2rem;
  }
  .u-mt-80--xl {
    margin-top: 8rem;
  }
  .u-mt-88--xl {
    margin-top: 8.8rem;
  }
  .u-mt-96--xl {
    margin-top: 9.6rem;
  }
  .u-mb-auto--xl {
    margin-bottom: auto;
  }
  .u-mb-0--xl {
    margin-bottom: 0;
  }
  .u-mb-8--xl {
    margin-bottom: 0.8rem;
  }
  .u-mb-16--xl {
    margin-bottom: 1.6rem;
  }
  .u-mb-24--xl {
    margin-bottom: 2.4rem;
  }
  .u-mb-32--xl {
    margin-bottom: 3.2rem;
  }
  .u-mb-40--xl {
    margin-bottom: 4rem;
  }
  .u-mb-48--xl {
    margin-bottom: 4.8rem;
  }
  .u-mb-56--xl {
    margin-bottom: 5.6rem;
  }
  .u-mb-64--xl {
    margin-bottom: 6.4rem;
  }
  .u-mb-72--xl {
    margin-bottom: 7.2rem;
  }
  .u-mb-80--xl {
    margin-bottom: 8rem;
  }
  .u-mb-88--xl {
    margin-bottom: 8.8rem;
  }
  .u-mb-96--xl {
    margin-bottom: 9.6rem;
  }
}
@media only screen and (min-width: 1920px) {
  .u-m-auto--xxl {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .u-m-0--xxl {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .u-m-8--xxl {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .u-m-16--xxl {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .u-m-24--xxl {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .u-m-32--xxl {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
    margin-left: 3.2rem;
    margin-right: 3.2rem;
  }
  .u-m-40--xxl {
    margin-top: 4rem;
    margin-bottom: 4rem;
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .u-m-48--xxl {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
  .u-m-56--xxl {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem;
    margin-left: 5.6rem;
    margin-right: 5.6rem;
  }
  .u-m-64--xxl {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem;
    margin-left: 6.4rem;
    margin-right: 6.4rem;
  }
  .u-m-72--xxl {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem;
    margin-left: 7.2rem;
    margin-right: 7.2rem;
  }
  .u-m-80--xxl {
    margin-top: 8rem;
    margin-bottom: 8rem;
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .u-m-88--xxl {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem;
    margin-left: 8.8rem;
    margin-right: 8.8rem;
  }
  .u-m-96--xxl {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem;
    margin-left: 9.6rem;
    margin-right: 9.6rem;
  }
  .u-mx-auto--xxl {
    margin-left: auto;
    margin-right: auto;
  }
  .u-mx-0--xxl {
    margin-left: 0;
    margin-right: 0;
  }
  .u-mx-8--xxl {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .u-mx-16--xxl {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .u-mx-24--xxl {
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
  .u-mx-32--xxl {
    margin-left: 3.2rem;
    margin-right: 3.2rem;
  }
  .u-mx-40--xxl {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .u-mx-48--xxl {
    margin-left: 4.8rem;
    margin-right: 4.8rem;
  }
  .u-mx-56--xxl {
    margin-left: 5.6rem;
    margin-right: 5.6rem;
  }
  .u-mx-64--xxl {
    margin-left: 6.4rem;
    margin-right: 6.4rem;
  }
  .u-mx-72--xxl {
    margin-left: 7.2rem;
    margin-right: 7.2rem;
  }
  .u-mx-80--xxl {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .u-mx-88--xxl {
    margin-left: 8.8rem;
    margin-right: 8.8rem;
  }
  .u-mx-96--xxl {
    margin-left: 9.6rem;
    margin-right: 9.6rem;
  }
  .u-ml-auto--xxl {
    margin-left: auto;
  }
  .u-ml-0--xxl {
    margin-left: 0;
  }
  .u-ml-8--xxl {
    margin-left: 0.8rem;
  }
  .u-ml-16--xxl {
    margin-left: 1.6rem;
  }
  .u-ml-24--xxl {
    margin-left: 2.4rem;
  }
  .u-ml-32--xxl {
    margin-left: 3.2rem;
  }
  .u-ml-40--xxl {
    margin-left: 4rem;
  }
  .u-ml-48--xxl {
    margin-left: 4.8rem;
  }
  .u-ml-56--xxl {
    margin-left: 5.6rem;
  }
  .u-ml-64--xxl {
    margin-left: 6.4rem;
  }
  .u-ml-72--xxl {
    margin-left: 7.2rem;
  }
  .u-ml-80--xxl {
    margin-left: 8rem;
  }
  .u-ml-88--xxl {
    margin-left: 8.8rem;
  }
  .u-ml-96--xxl {
    margin-left: 9.6rem;
  }
  .u-mr-auto--xxl {
    margin-right: auto;
  }
  .u-mr-0--xxl {
    margin-right: 0;
  }
  .u-mr-8--xxl {
    margin-right: 0.8rem;
  }
  .u-mr-16--xxl {
    margin-right: 1.6rem;
  }
  .u-mr-24--xxl {
    margin-right: 2.4rem;
  }
  .u-mr-32--xxl {
    margin-right: 3.2rem;
  }
  .u-mr-40--xxl {
    margin-right: 4rem;
  }
  .u-mr-48--xxl {
    margin-right: 4.8rem;
  }
  .u-mr-56--xxl {
    margin-right: 5.6rem;
  }
  .u-mr-64--xxl {
    margin-right: 6.4rem;
  }
  .u-mr-72--xxl {
    margin-right: 7.2rem;
  }
  .u-mr-80--xxl {
    margin-right: 8rem;
  }
  .u-mr-88--xxl {
    margin-right: 8.8rem;
  }
  .u-mr-96--xxl {
    margin-right: 9.6rem;
  }
  .u-my-auto--xxl {
    margin-top: auto;
    margin-bottom: auto;
  }
  .u-my-0--xxl {
    margin-top: 0;
    margin-bottom: 0;
  }
  .u-my-8--xxl {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
  .u-my-16--xxl {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
  }
  .u-my-24--xxl {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
  }
  .u-my-32--xxl {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
  }
  .u-my-40--xxl {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .u-my-48--xxl {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
  }
  .u-my-56--xxl {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem;
  }
  .u-my-64--xxl {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem;
  }
  .u-my-72--xxl {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem;
  }
  .u-my-80--xxl {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .u-my-88--xxl {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem;
  }
  .u-my-96--xxl {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem;
  }
  .u-mt-auto--xxl {
    margin-top: auto;
  }
  .u-mt-0--xxl {
    margin-top: 0;
  }
  .u-mt-8--xxl {
    margin-top: 0.8rem;
  }
  .u-mt-16--xxl {
    margin-top: 1.6rem;
  }
  .u-mt-24--xxl {
    margin-top: 2.4rem;
  }
  .u-mt-32--xxl {
    margin-top: 3.2rem;
  }
  .u-mt-40--xxl {
    margin-top: 4rem;
  }
  .u-mt-48--xxl {
    margin-top: 4.8rem;
  }
  .u-mt-56--xxl {
    margin-top: 5.6rem;
  }
  .u-mt-64--xxl {
    margin-top: 6.4rem;
  }
  .u-mt-72--xxl {
    margin-top: 7.2rem;
  }
  .u-mt-80--xxl {
    margin-top: 8rem;
  }
  .u-mt-88--xxl {
    margin-top: 8.8rem;
  }
  .u-mt-96--xxl {
    margin-top: 9.6rem;
  }
  .u-mb-auto--xxl {
    margin-bottom: auto;
  }
  .u-mb-0--xxl {
    margin-bottom: 0;
  }
  .u-mb-8--xxl {
    margin-bottom: 0.8rem;
  }
  .u-mb-16--xxl {
    margin-bottom: 1.6rem;
  }
  .u-mb-24--xxl {
    margin-bottom: 2.4rem;
  }
  .u-mb-32--xxl {
    margin-bottom: 3.2rem;
  }
  .u-mb-40--xxl {
    margin-bottom: 4rem;
  }
  .u-mb-48--xxl {
    margin-bottom: 4.8rem;
  }
  .u-mb-56--xxl {
    margin-bottom: 5.6rem;
  }
  .u-mb-64--xxl {
    margin-bottom: 6.4rem;
  }
  .u-mb-72--xxl {
    margin-bottom: 7.2rem;
  }
  .u-mb-80--xxl {
    margin-bottom: 8rem;
  }
  .u-mb-88--xxl {
    margin-bottom: 8.8rem;
  }
  .u-mb-96--xxl {
    margin-bottom: 9.6rem;
  }
}
.u-p-auto {
  padding-top: auto;
  padding-bottom: auto;
  padding-left: auto;
  padding-right: auto;
}
.u-p-0 {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}
.u-p-8 {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}
.u-p-16 {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
.u-p-24 {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}
.u-p-32 {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
}
.u-p-40 {
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: 4rem;
  padding-right: 4rem;
}
.u-p-48 {
  padding-top: 4.8rem;
  padding-bottom: 4.8rem;
  padding-left: 4.8rem;
  padding-right: 4.8rem;
}
.u-p-56 {
  padding-top: 5.6rem;
  padding-bottom: 5.6rem;
  padding-left: 5.6rem;
  padding-right: 5.6rem;
}
.u-p-64 {
  padding-top: 6.4rem;
  padding-bottom: 6.4rem;
  padding-left: 6.4rem;
  padding-right: 6.4rem;
}
.u-p-72 {
  padding-top: 7.2rem;
  padding-bottom: 7.2rem;
  padding-left: 7.2rem;
  padding-right: 7.2rem;
}
.u-p-80 {
  padding-top: 8rem;
  padding-bottom: 8rem;
  padding-left: 8rem;
  padding-right: 8rem;
}
.u-p-88 {
  padding-top: 8.8rem;
  padding-bottom: 8.8rem;
  padding-left: 8.8rem;
  padding-right: 8.8rem;
}
.u-p-96 {
  padding-top: 9.6rem;
  padding-bottom: 9.6rem;
  padding-left: 9.6rem;
  padding-right: 9.6rem;
}

.u-px-auto {
  padding-left: auto;
  padding-right: auto;
}
.u-px-0 {
  padding-left: 0;
  padding-right: 0;
}
.u-px-8 {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}
.u-px-16 {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
.u-px-24 {
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}
.u-px-32 {
  padding-left: 3.2rem;
  padding-right: 3.2rem;
}
.u-px-40 {
  padding-left: 4rem;
  padding-right: 4rem;
}
.u-px-48 {
  padding-left: 4.8rem;
  padding-right: 4.8rem;
}
.u-px-56 {
  padding-left: 5.6rem;
  padding-right: 5.6rem;
}
.u-px-64 {
  padding-left: 6.4rem;
  padding-right: 6.4rem;
}
.u-px-72 {
  padding-left: 7.2rem;
  padding-right: 7.2rem;
}
.u-px-80 {
  padding-left: 8rem;
  padding-right: 8rem;
}
.u-px-88 {
  padding-left: 8.8rem;
  padding-right: 8.8rem;
}
.u-px-96 {
  padding-left: 9.6rem;
  padding-right: 9.6rem;
}

.u-pl-auto {
  padding-left: auto;
}
.u-pl-0 {
  padding-left: 0;
}
.u-pl-8 {
  padding-left: 0.8rem;
}
.u-pl-16 {
  padding-left: 1.6rem;
}
.u-pl-24 {
  padding-left: 2.4rem;
}
.u-pl-32 {
  padding-left: 3.2rem;
}
.u-pl-40 {
  padding-left: 4rem;
}
.u-pl-48 {
  padding-left: 4.8rem;
}
.u-pl-56 {
  padding-left: 5.6rem;
}
.u-pl-64 {
  padding-left: 6.4rem;
}
.u-pl-72 {
  padding-left: 7.2rem;
}
.u-pl-80 {
  padding-left: 8rem;
}
.u-pl-88 {
  padding-left: 8.8rem;
}
.u-pl-96 {
  padding-left: 9.6rem;
}

.u-pr-auto {
  padding-right: auto;
}
.u-pr-0 {
  padding-right: 0;
}
.u-pr-8 {
  padding-right: 0.8rem;
}
.u-pr-16 {
  padding-right: 1.6rem;
}
.u-pr-24 {
  padding-right: 2.4rem;
}
.u-pr-32 {
  padding-right: 3.2rem;
}
.u-pr-40 {
  padding-right: 4rem;
}
.u-pr-48 {
  padding-right: 4.8rem;
}
.u-pr-56 {
  padding-right: 5.6rem;
}
.u-pr-64 {
  padding-right: 6.4rem;
}
.u-pr-72 {
  padding-right: 7.2rem;
}
.u-pr-80 {
  padding-right: 8rem;
}
.u-pr-88 {
  padding-right: 8.8rem;
}
.u-pr-96 {
  padding-right: 9.6rem;
}

.u-py-auto {
  padding-top: auto;
  padding-bottom: auto;
}
.u-py-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.u-py-8 {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}
.u-py-16 {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
}
.u-py-24 {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
}
.u-py-32 {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
}
.u-py-40 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.u-py-48 {
  padding-top: 4.8rem;
  padding-bottom: 4.8rem;
}
.u-py-56 {
  padding-top: 5.6rem;
  padding-bottom: 5.6rem;
}
.u-py-64 {
  padding-top: 6.4rem;
  padding-bottom: 6.4rem;
}
.u-py-72 {
  padding-top: 7.2rem;
  padding-bottom: 7.2rem;
}
.u-py-80 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}
.u-py-88 {
  padding-top: 8.8rem;
  padding-bottom: 8.8rem;
}
.u-py-96 {
  padding-top: 9.6rem;
  padding-bottom: 9.6rem;
}

.u-pt-auto {
  padding-top: auto;
}
.u-pt-0 {
  padding-top: 0;
}
.u-pt-8 {
  padding-top: 0.8rem;
}
.u-pt-16 {
  padding-top: 1.6rem;
}
.u-pt-24 {
  padding-top: 2.4rem;
}
.u-pt-32 {
  padding-top: 3.2rem;
}
.u-pt-40 {
  padding-top: 4rem;
}
.u-pt-48 {
  padding-top: 4.8rem;
}
.u-pt-56 {
  padding-top: 5.6rem;
}
.u-pt-64 {
  padding-top: 6.4rem;
}
.u-pt-72 {
  padding-top: 7.2rem;
}
.u-pt-80 {
  padding-top: 8rem;
}
.u-pt-88 {
  padding-top: 8.8rem;
}
.u-pt-96 {
  padding-top: 9.6rem;
}

.u-pb-auto {
  padding-bottom: auto;
}
.u-pb-0 {
  padding-bottom: 0;
}
.u-pb-8 {
  padding-bottom: 0.8rem;
}
.u-pb-16 {
  padding-bottom: 1.6rem;
}
.u-pb-24 {
  padding-bottom: 2.4rem;
}
.u-pb-32 {
  padding-bottom: 3.2rem;
}
.u-pb-40 {
  padding-bottom: 4rem;
}
.u-pb-48 {
  padding-bottom: 4.8rem;
}
.u-pb-56 {
  padding-bottom: 5.6rem;
}
.u-pb-64 {
  padding-bottom: 6.4rem;
}
.u-pb-72 {
  padding-bottom: 7.2rem;
}
.u-pb-80 {
  padding-bottom: 8rem;
}
.u-pb-88 {
  padding-bottom: 8.8rem;
}
.u-pb-96 {
  padding-bottom: 9.6rem;
}

@media only screen and (min-width: 480px) {
  .u-p-auto--xs {
    padding-top: auto;
    padding-bottom: auto;
    padding-left: auto;
    padding-right: auto;
  }
  .u-p-0--xs {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .u-p-8--xs {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .u-p-16--xs {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .u-p-24--xs {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .u-p-32--xs {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
  .u-p-40--xs {
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .u-p-48--xs {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
  .u-p-56--xs {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem;
    padding-left: 5.6rem;
    padding-right: 5.6rem;
  }
  .u-p-64--xs {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;
    padding-left: 6.4rem;
    padding-right: 6.4rem;
  }
  .u-p-72--xs {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem;
    padding-left: 7.2rem;
    padding-right: 7.2rem;
  }
  .u-p-80--xs {
    padding-top: 8rem;
    padding-bottom: 8rem;
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .u-p-88--xs {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem;
    padding-left: 8.8rem;
    padding-right: 8.8rem;
  }
  .u-p-96--xs {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem;
    padding-left: 9.6rem;
    padding-right: 9.6rem;
  }
  .u-px-auto--xs {
    padding-left: auto;
    padding-right: auto;
  }
  .u-px-0--xs {
    padding-left: 0;
    padding-right: 0;
  }
  .u-px-8--xs {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .u-px-16--xs {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .u-px-24--xs {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .u-px-32--xs {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
  .u-px-40--xs {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .u-px-48--xs {
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
  .u-px-56--xs {
    padding-left: 5.6rem;
    padding-right: 5.6rem;
  }
  .u-px-64--xs {
    padding-left: 6.4rem;
    padding-right: 6.4rem;
  }
  .u-px-72--xs {
    padding-left: 7.2rem;
    padding-right: 7.2rem;
  }
  .u-px-80--xs {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .u-px-88--xs {
    padding-left: 8.8rem;
    padding-right: 8.8rem;
  }
  .u-px-96--xs {
    padding-left: 9.6rem;
    padding-right: 9.6rem;
  }
  .u-pl-auto--xs {
    padding-left: auto;
  }
  .u-pl-0--xs {
    padding-left: 0;
  }
  .u-pl-8--xs {
    padding-left: 0.8rem;
  }
  .u-pl-16--xs {
    padding-left: 1.6rem;
  }
  .u-pl-24--xs {
    padding-left: 2.4rem;
  }
  .u-pl-32--xs {
    padding-left: 3.2rem;
  }
  .u-pl-40--xs {
    padding-left: 4rem;
  }
  .u-pl-48--xs {
    padding-left: 4.8rem;
  }
  .u-pl-56--xs {
    padding-left: 5.6rem;
  }
  .u-pl-64--xs {
    padding-left: 6.4rem;
  }
  .u-pl-72--xs {
    padding-left: 7.2rem;
  }
  .u-pl-80--xs {
    padding-left: 8rem;
  }
  .u-pl-88--xs {
    padding-left: 8.8rem;
  }
  .u-pl-96--xs {
    padding-left: 9.6rem;
  }
  .u-pr-auto--xs {
    padding-right: auto;
  }
  .u-pr-0--xs {
    padding-right: 0;
  }
  .u-pr-8--xs {
    padding-right: 0.8rem;
  }
  .u-pr-16--xs {
    padding-right: 1.6rem;
  }
  .u-pr-24--xs {
    padding-right: 2.4rem;
  }
  .u-pr-32--xs {
    padding-right: 3.2rem;
  }
  .u-pr-40--xs {
    padding-right: 4rem;
  }
  .u-pr-48--xs {
    padding-right: 4.8rem;
  }
  .u-pr-56--xs {
    padding-right: 5.6rem;
  }
  .u-pr-64--xs {
    padding-right: 6.4rem;
  }
  .u-pr-72--xs {
    padding-right: 7.2rem;
  }
  .u-pr-80--xs {
    padding-right: 8rem;
  }
  .u-pr-88--xs {
    padding-right: 8.8rem;
  }
  .u-pr-96--xs {
    padding-right: 9.6rem;
  }
  .u-py-auto--xs {
    padding-top: auto;
    padding-bottom: auto;
  }
  .u-py-0--xs {
    padding-top: 0;
    padding-bottom: 0;
  }
  .u-py-8--xs {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
  .u-py-16--xs {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }
  .u-py-24--xs {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
  }
  .u-py-32--xs {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
  }
  .u-py-40--xs {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .u-py-48--xs {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
  }
  .u-py-56--xs {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem;
  }
  .u-py-64--xs {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;
  }
  .u-py-72--xs {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem;
  }
  .u-py-80--xs {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .u-py-88--xs {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem;
  }
  .u-py-96--xs {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem;
  }
  .u-pt-auto--xs {
    padding-top: auto;
  }
  .u-pt-0--xs {
    padding-top: 0;
  }
  .u-pt-8--xs {
    padding-top: 0.8rem;
  }
  .u-pt-16--xs {
    padding-top: 1.6rem;
  }
  .u-pt-24--xs {
    padding-top: 2.4rem;
  }
  .u-pt-32--xs {
    padding-top: 3.2rem;
  }
  .u-pt-40--xs {
    padding-top: 4rem;
  }
  .u-pt-48--xs {
    padding-top: 4.8rem;
  }
  .u-pt-56--xs {
    padding-top: 5.6rem;
  }
  .u-pt-64--xs {
    padding-top: 6.4rem;
  }
  .u-pt-72--xs {
    padding-top: 7.2rem;
  }
  .u-pt-80--xs {
    padding-top: 8rem;
  }
  .u-pt-88--xs {
    padding-top: 8.8rem;
  }
  .u-pt-96--xs {
    padding-top: 9.6rem;
  }
  .u-pb-auto--xs {
    padding-bottom: auto;
  }
  .u-pb-0--xs {
    padding-bottom: 0;
  }
  .u-pb-8--xs {
    padding-bottom: 0.8rem;
  }
  .u-pb-16--xs {
    padding-bottom: 1.6rem;
  }
  .u-pb-24--xs {
    padding-bottom: 2.4rem;
  }
  .u-pb-32--xs {
    padding-bottom: 3.2rem;
  }
  .u-pb-40--xs {
    padding-bottom: 4rem;
  }
  .u-pb-48--xs {
    padding-bottom: 4.8rem;
  }
  .u-pb-56--xs {
    padding-bottom: 5.6rem;
  }
  .u-pb-64--xs {
    padding-bottom: 6.4rem;
  }
  .u-pb-72--xs {
    padding-bottom: 7.2rem;
  }
  .u-pb-80--xs {
    padding-bottom: 8rem;
  }
  .u-pb-88--xs {
    padding-bottom: 8.8rem;
  }
  .u-pb-96--xs {
    padding-bottom: 9.6rem;
  }
}
@media only screen and (min-width: 640px) {
  .u-p-auto--sm {
    padding-top: auto;
    padding-bottom: auto;
    padding-left: auto;
    padding-right: auto;
  }
  .u-p-0--sm {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .u-p-8--sm {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .u-p-16--sm {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .u-p-24--sm {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .u-p-32--sm {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
  .u-p-40--sm {
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .u-p-48--sm {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
  .u-p-56--sm {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem;
    padding-left: 5.6rem;
    padding-right: 5.6rem;
  }
  .u-p-64--sm {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;
    padding-left: 6.4rem;
    padding-right: 6.4rem;
  }
  .u-p-72--sm {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem;
    padding-left: 7.2rem;
    padding-right: 7.2rem;
  }
  .u-p-80--sm {
    padding-top: 8rem;
    padding-bottom: 8rem;
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .u-p-88--sm {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem;
    padding-left: 8.8rem;
    padding-right: 8.8rem;
  }
  .u-p-96--sm {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem;
    padding-left: 9.6rem;
    padding-right: 9.6rem;
  }
  .u-px-auto--sm {
    padding-left: auto;
    padding-right: auto;
  }
  .u-px-0--sm {
    padding-left: 0;
    padding-right: 0;
  }
  .u-px-8--sm {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .u-px-16--sm {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .u-px-24--sm {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .u-px-32--sm {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
  .u-px-40--sm {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .u-px-48--sm {
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
  .u-px-56--sm {
    padding-left: 5.6rem;
    padding-right: 5.6rem;
  }
  .u-px-64--sm {
    padding-left: 6.4rem;
    padding-right: 6.4rem;
  }
  .u-px-72--sm {
    padding-left: 7.2rem;
    padding-right: 7.2rem;
  }
  .u-px-80--sm {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .u-px-88--sm {
    padding-left: 8.8rem;
    padding-right: 8.8rem;
  }
  .u-px-96--sm {
    padding-left: 9.6rem;
    padding-right: 9.6rem;
  }
  .u-pl-auto--sm {
    padding-left: auto;
  }
  .u-pl-0--sm {
    padding-left: 0;
  }
  .u-pl-8--sm {
    padding-left: 0.8rem;
  }
  .u-pl-16--sm {
    padding-left: 1.6rem;
  }
  .u-pl-24--sm {
    padding-left: 2.4rem;
  }
  .u-pl-32--sm {
    padding-left: 3.2rem;
  }
  .u-pl-40--sm {
    padding-left: 4rem;
  }
  .u-pl-48--sm {
    padding-left: 4.8rem;
  }
  .u-pl-56--sm {
    padding-left: 5.6rem;
  }
  .u-pl-64--sm {
    padding-left: 6.4rem;
  }
  .u-pl-72--sm {
    padding-left: 7.2rem;
  }
  .u-pl-80--sm {
    padding-left: 8rem;
  }
  .u-pl-88--sm {
    padding-left: 8.8rem;
  }
  .u-pl-96--sm {
    padding-left: 9.6rem;
  }
  .u-pr-auto--sm {
    padding-right: auto;
  }
  .u-pr-0--sm {
    padding-right: 0;
  }
  .u-pr-8--sm {
    padding-right: 0.8rem;
  }
  .u-pr-16--sm {
    padding-right: 1.6rem;
  }
  .u-pr-24--sm {
    padding-right: 2.4rem;
  }
  .u-pr-32--sm {
    padding-right: 3.2rem;
  }
  .u-pr-40--sm {
    padding-right: 4rem;
  }
  .u-pr-48--sm {
    padding-right: 4.8rem;
  }
  .u-pr-56--sm {
    padding-right: 5.6rem;
  }
  .u-pr-64--sm {
    padding-right: 6.4rem;
  }
  .u-pr-72--sm {
    padding-right: 7.2rem;
  }
  .u-pr-80--sm {
    padding-right: 8rem;
  }
  .u-pr-88--sm {
    padding-right: 8.8rem;
  }
  .u-pr-96--sm {
    padding-right: 9.6rem;
  }
  .u-py-auto--sm {
    padding-top: auto;
    padding-bottom: auto;
  }
  .u-py-0--sm {
    padding-top: 0;
    padding-bottom: 0;
  }
  .u-py-8--sm {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
  .u-py-16--sm {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }
  .u-py-24--sm {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
  }
  .u-py-32--sm {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
  }
  .u-py-40--sm {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .u-py-48--sm {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
  }
  .u-py-56--sm {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem;
  }
  .u-py-64--sm {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;
  }
  .u-py-72--sm {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem;
  }
  .u-py-80--sm {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .u-py-88--sm {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem;
  }
  .u-py-96--sm {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem;
  }
  .u-pt-auto--sm {
    padding-top: auto;
  }
  .u-pt-0--sm {
    padding-top: 0;
  }
  .u-pt-8--sm {
    padding-top: 0.8rem;
  }
  .u-pt-16--sm {
    padding-top: 1.6rem;
  }
  .u-pt-24--sm {
    padding-top: 2.4rem;
  }
  .u-pt-32--sm {
    padding-top: 3.2rem;
  }
  .u-pt-40--sm {
    padding-top: 4rem;
  }
  .u-pt-48--sm {
    padding-top: 4.8rem;
  }
  .u-pt-56--sm {
    padding-top: 5.6rem;
  }
  .u-pt-64--sm {
    padding-top: 6.4rem;
  }
  .u-pt-72--sm {
    padding-top: 7.2rem;
  }
  .u-pt-80--sm {
    padding-top: 8rem;
  }
  .u-pt-88--sm {
    padding-top: 8.8rem;
  }
  .u-pt-96--sm {
    padding-top: 9.6rem;
  }
  .u-pb-auto--sm {
    padding-bottom: auto;
  }
  .u-pb-0--sm {
    padding-bottom: 0;
  }
  .u-pb-8--sm {
    padding-bottom: 0.8rem;
  }
  .u-pb-16--sm {
    padding-bottom: 1.6rem;
  }
  .u-pb-24--sm {
    padding-bottom: 2.4rem;
  }
  .u-pb-32--sm {
    padding-bottom: 3.2rem;
  }
  .u-pb-40--sm {
    padding-bottom: 4rem;
  }
  .u-pb-48--sm {
    padding-bottom: 4.8rem;
  }
  .u-pb-56--sm {
    padding-bottom: 5.6rem;
  }
  .u-pb-64--sm {
    padding-bottom: 6.4rem;
  }
  .u-pb-72--sm {
    padding-bottom: 7.2rem;
  }
  .u-pb-80--sm {
    padding-bottom: 8rem;
  }
  .u-pb-88--sm {
    padding-bottom: 8.8rem;
  }
  .u-pb-96--sm {
    padding-bottom: 9.6rem;
  }
}
@media only screen and (min-width: 768px) {
  .u-p-auto--md {
    padding-top: auto;
    padding-bottom: auto;
    padding-left: auto;
    padding-right: auto;
  }
  .u-p-0--md {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .u-p-8--md {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .u-p-16--md {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .u-p-24--md {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .u-p-32--md {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
  .u-p-40--md {
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .u-p-48--md {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
  .u-p-56--md {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem;
    padding-left: 5.6rem;
    padding-right: 5.6rem;
  }
  .u-p-64--md {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;
    padding-left: 6.4rem;
    padding-right: 6.4rem;
  }
  .u-p-72--md {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem;
    padding-left: 7.2rem;
    padding-right: 7.2rem;
  }
  .u-p-80--md {
    padding-top: 8rem;
    padding-bottom: 8rem;
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .u-p-88--md {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem;
    padding-left: 8.8rem;
    padding-right: 8.8rem;
  }
  .u-p-96--md {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem;
    padding-left: 9.6rem;
    padding-right: 9.6rem;
  }
  .u-px-auto--md {
    padding-left: auto;
    padding-right: auto;
  }
  .u-px-0--md {
    padding-left: 0;
    padding-right: 0;
  }
  .u-px-8--md {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .u-px-16--md {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .u-px-24--md {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .u-px-32--md {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
  .u-px-40--md {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .u-px-48--md {
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
  .u-px-56--md {
    padding-left: 5.6rem;
    padding-right: 5.6rem;
  }
  .u-px-64--md {
    padding-left: 6.4rem;
    padding-right: 6.4rem;
  }
  .u-px-72--md {
    padding-left: 7.2rem;
    padding-right: 7.2rem;
  }
  .u-px-80--md {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .u-px-88--md {
    padding-left: 8.8rem;
    padding-right: 8.8rem;
  }
  .u-px-96--md {
    padding-left: 9.6rem;
    padding-right: 9.6rem;
  }
  .u-pl-auto--md {
    padding-left: auto;
  }
  .u-pl-0--md {
    padding-left: 0;
  }
  .u-pl-8--md {
    padding-left: 0.8rem;
  }
  .u-pl-16--md {
    padding-left: 1.6rem;
  }
  .u-pl-24--md {
    padding-left: 2.4rem;
  }
  .u-pl-32--md {
    padding-left: 3.2rem;
  }
  .u-pl-40--md {
    padding-left: 4rem;
  }
  .u-pl-48--md {
    padding-left: 4.8rem;
  }
  .u-pl-56--md {
    padding-left: 5.6rem;
  }
  .u-pl-64--md {
    padding-left: 6.4rem;
  }
  .u-pl-72--md {
    padding-left: 7.2rem;
  }
  .u-pl-80--md {
    padding-left: 8rem;
  }
  .u-pl-88--md {
    padding-left: 8.8rem;
  }
  .u-pl-96--md {
    padding-left: 9.6rem;
  }
  .u-pr-auto--md {
    padding-right: auto;
  }
  .u-pr-0--md {
    padding-right: 0;
  }
  .u-pr-8--md {
    padding-right: 0.8rem;
  }
  .u-pr-16--md {
    padding-right: 1.6rem;
  }
  .u-pr-24--md {
    padding-right: 2.4rem;
  }
  .u-pr-32--md {
    padding-right: 3.2rem;
  }
  .u-pr-40--md {
    padding-right: 4rem;
  }
  .u-pr-48--md {
    padding-right: 4.8rem;
  }
  .u-pr-56--md {
    padding-right: 5.6rem;
  }
  .u-pr-64--md {
    padding-right: 6.4rem;
  }
  .u-pr-72--md {
    padding-right: 7.2rem;
  }
  .u-pr-80--md {
    padding-right: 8rem;
  }
  .u-pr-88--md {
    padding-right: 8.8rem;
  }
  .u-pr-96--md {
    padding-right: 9.6rem;
  }
  .u-py-auto--md {
    padding-top: auto;
    padding-bottom: auto;
  }
  .u-py-0--md {
    padding-top: 0;
    padding-bottom: 0;
  }
  .u-py-8--md {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
  .u-py-16--md {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }
  .u-py-24--md {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
  }
  .u-py-32--md {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
  }
  .u-py-40--md {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .u-py-48--md {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
  }
  .u-py-56--md {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem;
  }
  .u-py-64--md {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;
  }
  .u-py-72--md {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem;
  }
  .u-py-80--md {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .u-py-88--md {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem;
  }
  .u-py-96--md {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem;
  }
  .u-pt-auto--md {
    padding-top: auto;
  }
  .u-pt-0--md {
    padding-top: 0;
  }
  .u-pt-8--md {
    padding-top: 0.8rem;
  }
  .u-pt-16--md {
    padding-top: 1.6rem;
  }
  .u-pt-24--md {
    padding-top: 2.4rem;
  }
  .u-pt-32--md {
    padding-top: 3.2rem;
  }
  .u-pt-40--md {
    padding-top: 4rem;
  }
  .u-pt-48--md {
    padding-top: 4.8rem;
  }
  .u-pt-56--md {
    padding-top: 5.6rem;
  }
  .u-pt-64--md {
    padding-top: 6.4rem;
  }
  .u-pt-72--md {
    padding-top: 7.2rem;
  }
  .u-pt-80--md {
    padding-top: 8rem;
  }
  .u-pt-88--md {
    padding-top: 8.8rem;
  }
  .u-pt-96--md {
    padding-top: 9.6rem;
  }
  .u-pb-auto--md {
    padding-bottom: auto;
  }
  .u-pb-0--md {
    padding-bottom: 0;
  }
  .u-pb-8--md {
    padding-bottom: 0.8rem;
  }
  .u-pb-16--md {
    padding-bottom: 1.6rem;
  }
  .u-pb-24--md {
    padding-bottom: 2.4rem;
  }
  .u-pb-32--md {
    padding-bottom: 3.2rem;
  }
  .u-pb-40--md {
    padding-bottom: 4rem;
  }
  .u-pb-48--md {
    padding-bottom: 4.8rem;
  }
  .u-pb-56--md {
    padding-bottom: 5.6rem;
  }
  .u-pb-64--md {
    padding-bottom: 6.4rem;
  }
  .u-pb-72--md {
    padding-bottom: 7.2rem;
  }
  .u-pb-80--md {
    padding-bottom: 8rem;
  }
  .u-pb-88--md {
    padding-bottom: 8.8rem;
  }
  .u-pb-96--md {
    padding-bottom: 9.6rem;
  }
}
@media only screen and (min-width: 1024px) {
  .u-p-auto--lg {
    padding-top: auto;
    padding-bottom: auto;
    padding-left: auto;
    padding-right: auto;
  }
  .u-p-0--lg {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .u-p-8--lg {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .u-p-16--lg {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .u-p-24--lg {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .u-p-32--lg {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
  .u-p-40--lg {
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .u-p-48--lg {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
  .u-p-56--lg {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem;
    padding-left: 5.6rem;
    padding-right: 5.6rem;
  }
  .u-p-64--lg {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;
    padding-left: 6.4rem;
    padding-right: 6.4rem;
  }
  .u-p-72--lg {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem;
    padding-left: 7.2rem;
    padding-right: 7.2rem;
  }
  .u-p-80--lg {
    padding-top: 8rem;
    padding-bottom: 8rem;
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .u-p-88--lg {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem;
    padding-left: 8.8rem;
    padding-right: 8.8rem;
  }
  .u-p-96--lg {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem;
    padding-left: 9.6rem;
    padding-right: 9.6rem;
  }
  .u-px-auto--lg {
    padding-left: auto;
    padding-right: auto;
  }
  .u-px-0--lg {
    padding-left: 0;
    padding-right: 0;
  }
  .u-px-8--lg {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .u-px-16--lg {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .u-px-24--lg {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .u-px-32--lg {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
  .u-px-40--lg {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .u-px-48--lg {
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
  .u-px-56--lg {
    padding-left: 5.6rem;
    padding-right: 5.6rem;
  }
  .u-px-64--lg {
    padding-left: 6.4rem;
    padding-right: 6.4rem;
  }
  .u-px-72--lg {
    padding-left: 7.2rem;
    padding-right: 7.2rem;
  }
  .u-px-80--lg {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .u-px-88--lg {
    padding-left: 8.8rem;
    padding-right: 8.8rem;
  }
  .u-px-96--lg {
    padding-left: 9.6rem;
    padding-right: 9.6rem;
  }
  .u-pl-auto--lg {
    padding-left: auto;
  }
  .u-pl-0--lg {
    padding-left: 0;
  }
  .u-pl-8--lg {
    padding-left: 0.8rem;
  }
  .u-pl-16--lg {
    padding-left: 1.6rem;
  }
  .u-pl-24--lg {
    padding-left: 2.4rem;
  }
  .u-pl-32--lg {
    padding-left: 3.2rem;
  }
  .u-pl-40--lg {
    padding-left: 4rem;
  }
  .u-pl-48--lg {
    padding-left: 4.8rem;
  }
  .u-pl-56--lg {
    padding-left: 5.6rem;
  }
  .u-pl-64--lg {
    padding-left: 6.4rem;
  }
  .u-pl-72--lg {
    padding-left: 7.2rem;
  }
  .u-pl-80--lg {
    padding-left: 8rem;
  }
  .u-pl-88--lg {
    padding-left: 8.8rem;
  }
  .u-pl-96--lg {
    padding-left: 9.6rem;
  }
  .u-pr-auto--lg {
    padding-right: auto;
  }
  .u-pr-0--lg {
    padding-right: 0;
  }
  .u-pr-8--lg {
    padding-right: 0.8rem;
  }
  .u-pr-16--lg {
    padding-right: 1.6rem;
  }
  .u-pr-24--lg {
    padding-right: 2.4rem;
  }
  .u-pr-32--lg {
    padding-right: 3.2rem;
  }
  .u-pr-40--lg {
    padding-right: 4rem;
  }
  .u-pr-48--lg {
    padding-right: 4.8rem;
  }
  .u-pr-56--lg {
    padding-right: 5.6rem;
  }
  .u-pr-64--lg {
    padding-right: 6.4rem;
  }
  .u-pr-72--lg {
    padding-right: 7.2rem;
  }
  .u-pr-80--lg {
    padding-right: 8rem;
  }
  .u-pr-88--lg {
    padding-right: 8.8rem;
  }
  .u-pr-96--lg {
    padding-right: 9.6rem;
  }
  .u-py-auto--lg {
    padding-top: auto;
    padding-bottom: auto;
  }
  .u-py-0--lg {
    padding-top: 0;
    padding-bottom: 0;
  }
  .u-py-8--lg {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
  .u-py-16--lg {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }
  .u-py-24--lg {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
  }
  .u-py-32--lg {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
  }
  .u-py-40--lg {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .u-py-48--lg {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
  }
  .u-py-56--lg {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem;
  }
  .u-py-64--lg {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;
  }
  .u-py-72--lg {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem;
  }
  .u-py-80--lg {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .u-py-88--lg {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem;
  }
  .u-py-96--lg {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem;
  }
  .u-pt-auto--lg {
    padding-top: auto;
  }
  .u-pt-0--lg {
    padding-top: 0;
  }
  .u-pt-8--lg {
    padding-top: 0.8rem;
  }
  .u-pt-16--lg {
    padding-top: 1.6rem;
  }
  .u-pt-24--lg {
    padding-top: 2.4rem;
  }
  .u-pt-32--lg {
    padding-top: 3.2rem;
  }
  .u-pt-40--lg {
    padding-top: 4rem;
  }
  .u-pt-48--lg {
    padding-top: 4.8rem;
  }
  .u-pt-56--lg {
    padding-top: 5.6rem;
  }
  .u-pt-64--lg {
    padding-top: 6.4rem;
  }
  .u-pt-72--lg {
    padding-top: 7.2rem;
  }
  .u-pt-80--lg {
    padding-top: 8rem;
  }
  .u-pt-88--lg {
    padding-top: 8.8rem;
  }
  .u-pt-96--lg {
    padding-top: 9.6rem;
  }
  .u-pb-auto--lg {
    padding-bottom: auto;
  }
  .u-pb-0--lg {
    padding-bottom: 0;
  }
  .u-pb-8--lg {
    padding-bottom: 0.8rem;
  }
  .u-pb-16--lg {
    padding-bottom: 1.6rem;
  }
  .u-pb-24--lg {
    padding-bottom: 2.4rem;
  }
  .u-pb-32--lg {
    padding-bottom: 3.2rem;
  }
  .u-pb-40--lg {
    padding-bottom: 4rem;
  }
  .u-pb-48--lg {
    padding-bottom: 4.8rem;
  }
  .u-pb-56--lg {
    padding-bottom: 5.6rem;
  }
  .u-pb-64--lg {
    padding-bottom: 6.4rem;
  }
  .u-pb-72--lg {
    padding-bottom: 7.2rem;
  }
  .u-pb-80--lg {
    padding-bottom: 8rem;
  }
  .u-pb-88--lg {
    padding-bottom: 8.8rem;
  }
  .u-pb-96--lg {
    padding-bottom: 9.6rem;
  }
}
@media only screen and (min-width: 1280px) {
  .u-p-auto--xl {
    padding-top: auto;
    padding-bottom: auto;
    padding-left: auto;
    padding-right: auto;
  }
  .u-p-0--xl {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .u-p-8--xl {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .u-p-16--xl {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .u-p-24--xl {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .u-p-32--xl {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
  .u-p-40--xl {
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .u-p-48--xl {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
  .u-p-56--xl {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem;
    padding-left: 5.6rem;
    padding-right: 5.6rem;
  }
  .u-p-64--xl {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;
    padding-left: 6.4rem;
    padding-right: 6.4rem;
  }
  .u-p-72--xl {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem;
    padding-left: 7.2rem;
    padding-right: 7.2rem;
  }
  .u-p-80--xl {
    padding-top: 8rem;
    padding-bottom: 8rem;
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .u-p-88--xl {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem;
    padding-left: 8.8rem;
    padding-right: 8.8rem;
  }
  .u-p-96--xl {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem;
    padding-left: 9.6rem;
    padding-right: 9.6rem;
  }
  .u-px-auto--xl {
    padding-left: auto;
    padding-right: auto;
  }
  .u-px-0--xl {
    padding-left: 0;
    padding-right: 0;
  }
  .u-px-8--xl {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .u-px-16--xl {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .u-px-24--xl {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .u-px-32--xl {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
  .u-px-40--xl {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .u-px-48--xl {
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
  .u-px-56--xl {
    padding-left: 5.6rem;
    padding-right: 5.6rem;
  }
  .u-px-64--xl {
    padding-left: 6.4rem;
    padding-right: 6.4rem;
  }
  .u-px-72--xl {
    padding-left: 7.2rem;
    padding-right: 7.2rem;
  }
  .u-px-80--xl {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .u-px-88--xl {
    padding-left: 8.8rem;
    padding-right: 8.8rem;
  }
  .u-px-96--xl {
    padding-left: 9.6rem;
    padding-right: 9.6rem;
  }
  .u-pl-auto--xl {
    padding-left: auto;
  }
  .u-pl-0--xl {
    padding-left: 0;
  }
  .u-pl-8--xl {
    padding-left: 0.8rem;
  }
  .u-pl-16--xl {
    padding-left: 1.6rem;
  }
  .u-pl-24--xl {
    padding-left: 2.4rem;
  }
  .u-pl-32--xl {
    padding-left: 3.2rem;
  }
  .u-pl-40--xl {
    padding-left: 4rem;
  }
  .u-pl-48--xl {
    padding-left: 4.8rem;
  }
  .u-pl-56--xl {
    padding-left: 5.6rem;
  }
  .u-pl-64--xl {
    padding-left: 6.4rem;
  }
  .u-pl-72--xl {
    padding-left: 7.2rem;
  }
  .u-pl-80--xl {
    padding-left: 8rem;
  }
  .u-pl-88--xl {
    padding-left: 8.8rem;
  }
  .u-pl-96--xl {
    padding-left: 9.6rem;
  }
  .u-pr-auto--xl {
    padding-right: auto;
  }
  .u-pr-0--xl {
    padding-right: 0;
  }
  .u-pr-8--xl {
    padding-right: 0.8rem;
  }
  .u-pr-16--xl {
    padding-right: 1.6rem;
  }
  .u-pr-24--xl {
    padding-right: 2.4rem;
  }
  .u-pr-32--xl {
    padding-right: 3.2rem;
  }
  .u-pr-40--xl {
    padding-right: 4rem;
  }
  .u-pr-48--xl {
    padding-right: 4.8rem;
  }
  .u-pr-56--xl {
    padding-right: 5.6rem;
  }
  .u-pr-64--xl {
    padding-right: 6.4rem;
  }
  .u-pr-72--xl {
    padding-right: 7.2rem;
  }
  .u-pr-80--xl {
    padding-right: 8rem;
  }
  .u-pr-88--xl {
    padding-right: 8.8rem;
  }
  .u-pr-96--xl {
    padding-right: 9.6rem;
  }
  .u-py-auto--xl {
    padding-top: auto;
    padding-bottom: auto;
  }
  .u-py-0--xl {
    padding-top: 0;
    padding-bottom: 0;
  }
  .u-py-8--xl {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
  .u-py-16--xl {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }
  .u-py-24--xl {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
  }
  .u-py-32--xl {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
  }
  .u-py-40--xl {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .u-py-48--xl {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
  }
  .u-py-56--xl {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem;
  }
  .u-py-64--xl {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;
  }
  .u-py-72--xl {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem;
  }
  .u-py-80--xl {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .u-py-88--xl {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem;
  }
  .u-py-96--xl {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem;
  }
  .u-pt-auto--xl {
    padding-top: auto;
  }
  .u-pt-0--xl {
    padding-top: 0;
  }
  .u-pt-8--xl {
    padding-top: 0.8rem;
  }
  .u-pt-16--xl {
    padding-top: 1.6rem;
  }
  .u-pt-24--xl {
    padding-top: 2.4rem;
  }
  .u-pt-32--xl {
    padding-top: 3.2rem;
  }
  .u-pt-40--xl {
    padding-top: 4rem;
  }
  .u-pt-48--xl {
    padding-top: 4.8rem;
  }
  .u-pt-56--xl {
    padding-top: 5.6rem;
  }
  .u-pt-64--xl {
    padding-top: 6.4rem;
  }
  .u-pt-72--xl {
    padding-top: 7.2rem;
  }
  .u-pt-80--xl {
    padding-top: 8rem;
  }
  .u-pt-88--xl {
    padding-top: 8.8rem;
  }
  .u-pt-96--xl {
    padding-top: 9.6rem;
  }
  .u-pb-auto--xl {
    padding-bottom: auto;
  }
  .u-pb-0--xl {
    padding-bottom: 0;
  }
  .u-pb-8--xl {
    padding-bottom: 0.8rem;
  }
  .u-pb-16--xl {
    padding-bottom: 1.6rem;
  }
  .u-pb-24--xl {
    padding-bottom: 2.4rem;
  }
  .u-pb-32--xl {
    padding-bottom: 3.2rem;
  }
  .u-pb-40--xl {
    padding-bottom: 4rem;
  }
  .u-pb-48--xl {
    padding-bottom: 4.8rem;
  }
  .u-pb-56--xl {
    padding-bottom: 5.6rem;
  }
  .u-pb-64--xl {
    padding-bottom: 6.4rem;
  }
  .u-pb-72--xl {
    padding-bottom: 7.2rem;
  }
  .u-pb-80--xl {
    padding-bottom: 8rem;
  }
  .u-pb-88--xl {
    padding-bottom: 8.8rem;
  }
  .u-pb-96--xl {
    padding-bottom: 9.6rem;
  }
}
@media only screen and (min-width: 1920px) {
  .u-p-auto--xxl {
    padding-top: auto;
    padding-bottom: auto;
    padding-left: auto;
    padding-right: auto;
  }
  .u-p-0--xxl {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .u-p-8--xxl {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .u-p-16--xxl {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .u-p-24--xxl {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .u-p-32--xxl {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
  .u-p-40--xxl {
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .u-p-48--xxl {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
  .u-p-56--xxl {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem;
    padding-left: 5.6rem;
    padding-right: 5.6rem;
  }
  .u-p-64--xxl {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;
    padding-left: 6.4rem;
    padding-right: 6.4rem;
  }
  .u-p-72--xxl {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem;
    padding-left: 7.2rem;
    padding-right: 7.2rem;
  }
  .u-p-80--xxl {
    padding-top: 8rem;
    padding-bottom: 8rem;
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .u-p-88--xxl {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem;
    padding-left: 8.8rem;
    padding-right: 8.8rem;
  }
  .u-p-96--xxl {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem;
    padding-left: 9.6rem;
    padding-right: 9.6rem;
  }
  .u-px-auto--xxl {
    padding-left: auto;
    padding-right: auto;
  }
  .u-px-0--xxl {
    padding-left: 0;
    padding-right: 0;
  }
  .u-px-8--xxl {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .u-px-16--xxl {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .u-px-24--xxl {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .u-px-32--xxl {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
  .u-px-40--xxl {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .u-px-48--xxl {
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
  .u-px-56--xxl {
    padding-left: 5.6rem;
    padding-right: 5.6rem;
  }
  .u-px-64--xxl {
    padding-left: 6.4rem;
    padding-right: 6.4rem;
  }
  .u-px-72--xxl {
    padding-left: 7.2rem;
    padding-right: 7.2rem;
  }
  .u-px-80--xxl {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .u-px-88--xxl {
    padding-left: 8.8rem;
    padding-right: 8.8rem;
  }
  .u-px-96--xxl {
    padding-left: 9.6rem;
    padding-right: 9.6rem;
  }
  .u-pl-auto--xxl {
    padding-left: auto;
  }
  .u-pl-0--xxl {
    padding-left: 0;
  }
  .u-pl-8--xxl {
    padding-left: 0.8rem;
  }
  .u-pl-16--xxl {
    padding-left: 1.6rem;
  }
  .u-pl-24--xxl {
    padding-left: 2.4rem;
  }
  .u-pl-32--xxl {
    padding-left: 3.2rem;
  }
  .u-pl-40--xxl {
    padding-left: 4rem;
  }
  .u-pl-48--xxl {
    padding-left: 4.8rem;
  }
  .u-pl-56--xxl {
    padding-left: 5.6rem;
  }
  .u-pl-64--xxl {
    padding-left: 6.4rem;
  }
  .u-pl-72--xxl {
    padding-left: 7.2rem;
  }
  .u-pl-80--xxl {
    padding-left: 8rem;
  }
  .u-pl-88--xxl {
    padding-left: 8.8rem;
  }
  .u-pl-96--xxl {
    padding-left: 9.6rem;
  }
  .u-pr-auto--xxl {
    padding-right: auto;
  }
  .u-pr-0--xxl {
    padding-right: 0;
  }
  .u-pr-8--xxl {
    padding-right: 0.8rem;
  }
  .u-pr-16--xxl {
    padding-right: 1.6rem;
  }
  .u-pr-24--xxl {
    padding-right: 2.4rem;
  }
  .u-pr-32--xxl {
    padding-right: 3.2rem;
  }
  .u-pr-40--xxl {
    padding-right: 4rem;
  }
  .u-pr-48--xxl {
    padding-right: 4.8rem;
  }
  .u-pr-56--xxl {
    padding-right: 5.6rem;
  }
  .u-pr-64--xxl {
    padding-right: 6.4rem;
  }
  .u-pr-72--xxl {
    padding-right: 7.2rem;
  }
  .u-pr-80--xxl {
    padding-right: 8rem;
  }
  .u-pr-88--xxl {
    padding-right: 8.8rem;
  }
  .u-pr-96--xxl {
    padding-right: 9.6rem;
  }
  .u-py-auto--xxl {
    padding-top: auto;
    padding-bottom: auto;
  }
  .u-py-0--xxl {
    padding-top: 0;
    padding-bottom: 0;
  }
  .u-py-8--xxl {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
  .u-py-16--xxl {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }
  .u-py-24--xxl {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
  }
  .u-py-32--xxl {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
  }
  .u-py-40--xxl {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .u-py-48--xxl {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
  }
  .u-py-56--xxl {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem;
  }
  .u-py-64--xxl {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;
  }
  .u-py-72--xxl {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem;
  }
  .u-py-80--xxl {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .u-py-88--xxl {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem;
  }
  .u-py-96--xxl {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem;
  }
  .u-pt-auto--xxl {
    padding-top: auto;
  }
  .u-pt-0--xxl {
    padding-top: 0;
  }
  .u-pt-8--xxl {
    padding-top: 0.8rem;
  }
  .u-pt-16--xxl {
    padding-top: 1.6rem;
  }
  .u-pt-24--xxl {
    padding-top: 2.4rem;
  }
  .u-pt-32--xxl {
    padding-top: 3.2rem;
  }
  .u-pt-40--xxl {
    padding-top: 4rem;
  }
  .u-pt-48--xxl {
    padding-top: 4.8rem;
  }
  .u-pt-56--xxl {
    padding-top: 5.6rem;
  }
  .u-pt-64--xxl {
    padding-top: 6.4rem;
  }
  .u-pt-72--xxl {
    padding-top: 7.2rem;
  }
  .u-pt-80--xxl {
    padding-top: 8rem;
  }
  .u-pt-88--xxl {
    padding-top: 8.8rem;
  }
  .u-pt-96--xxl {
    padding-top: 9.6rem;
  }
  .u-pb-auto--xxl {
    padding-bottom: auto;
  }
  .u-pb-0--xxl {
    padding-bottom: 0;
  }
  .u-pb-8--xxl {
    padding-bottom: 0.8rem;
  }
  .u-pb-16--xxl {
    padding-bottom: 1.6rem;
  }
  .u-pb-24--xxl {
    padding-bottom: 2.4rem;
  }
  .u-pb-32--xxl {
    padding-bottom: 3.2rem;
  }
  .u-pb-40--xxl {
    padding-bottom: 4rem;
  }
  .u-pb-48--xxl {
    padding-bottom: 4.8rem;
  }
  .u-pb-56--xxl {
    padding-bottom: 5.6rem;
  }
  .u-pb-64--xxl {
    padding-bottom: 6.4rem;
  }
  .u-pb-72--xxl {
    padding-bottom: 7.2rem;
  }
  .u-pb-80--xxl {
    padding-bottom: 8rem;
  }
  .u-pb-88--xxl {
    padding-bottom: 8.8rem;
  }
  .u-pb-96--xxl {
    padding-bottom: 9.6rem;
  }
}
.u-shadow, .o-header, .c-nav-megamenu, .c-card, .c-dropdown__details, [open] .c-menu__icon {
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  gap: 4rem;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #ccc;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #ccc;
}

.mfp-preloader a:hover {
  color: #fff;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #fff;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #fff;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #ccc;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #fff;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3f3f3f;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #fff;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3f3f3f;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #f3f3f3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}