.c-button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
}

.c-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem 2.4rem;
  min-height: 4.4rem;
  font-family: $fontFamilyTitle;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid transparent;

  &:hover {
    cursor: pointer;
  }

  &--yellow {
    background-color: $colorYellow;

    &:hover {
      background-color: darken($colorYellow, 10%);
    }
  }

  &--silver {
    background-color: $colorSilver;

    &:hover {
      background-color: darken($colorSilver, 10%);
    }
  }

  &--black {
    background-color: $colorBlack;
    color: $colorWhite;

    &:hover {
      background-color: darken($colorBlack, 10%);
    }
  }

  &--white {
    background-color: $colorWhite;

    &:hover {
      background-color: darken($colorWhite, 10%);
    }
  }

  &--link {
    text-decoration: underline;

    &:hover {
      background-color: rgba($colorBlack, .1);
    }
  }
}
